import { useTranslation } from 'react-i18next';
import {
  Block,
  Center,
  Grid,
  HStack,
  Icon,
  IconTypes,
  Space,
  Typography,
  VStack,
  XelaColor
} from '@codepoint-pt/xela';
import { useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { AnyObject } from '../../models/Generic';
import { MUNICIPALITY_ADMIN } from '../../models/User';
import { UserContext } from '../../Context';
import ByGender from './ByGender';
import ByCountry from './ByCountry';

export interface AdminDashboard {
  users: number;
  itineraries: number;
  pois: number;
  byGender: AnyObject;
  byCountry: AnyObject;
}

interface Values {
  validated: number;
  awaitingValidation: number;
  rejected: number;
}

export interface MunicipalityDashboard {
  events: Values;
  itineraries: Values;
  pois: Values;
}

const DashboardPage = () => {
  const [adminData, setAdminData] = useState<AdminDashboard>();
  const [municipalityData, setMunicipalityData] = useState<MunicipalityDashboard>();
  const { t } = useTranslation();
  const { get } = useFetch('/info/statistics');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      const { data, success } = await get();
      if(success) {
        if(user?.type === MUNICIPALITY_ADMIN) setMunicipalityData(data);
        else setAdminData(data);
      }
    };
    init();
  }, []);

  const renderAdminCard = (
    title: string, 
    total: number, 
    icon: IconTypes, 
    bgIconColor: string, 
    iconColor: string
  ) => {
    return (
      <Grid.Col sm={4} xs={12}>
        <Block padding="20px 30px" bg="white" borderRadius="16px">
          <HStack spacing="16px">
            <Center
              width="52px"
              height="52px"
              borderRadius="99px"
              bg={bgIconColor}
            >
              <Icon
                icon={icon}
                size={28}
                color={iconColor}
              />
            </Center>
            <VStack width="auto">
              <Typography variant="title3">{total}</Typography>
              <Typography variant="caption" color={XelaColor.Gray8}>
                {t(title)}
              </Typography>
            </VStack>
          </HStack>
        </Block>
      </Grid.Col>
    );
  };

  const renderMunicipalityAdminCard = (
    title: string, 
    validated: number, 
    awaitingValidation: number, 
    rejected: number, 
    icon: IconTypes, 
    bgIconColor: string, 
    iconColor: string
  ) => {
    return (
      <Grid.Col sm={4} xs={12}>
        <Block padding="20px 30px" bg="white" borderRadius="16px">
          <VStack justifyContent='space-between'>
            <HStack justifyContent='space-between'>
              <Typography variant="subheadline" style={{ fontSize: 20 }} color={XelaColor.Gray3}>{t(title)}</Typography>
              <Center
                width="45px"
                height="45px"
                borderRadius="99px"
                bg={bgIconColor}
              >
                <Icon
                  icon={icon}
                  size={22}
                  color={iconColor}
                />
              </Center>
            </HStack>
            <VStack style={{ marginTop: '15px' }} spacing='3px'>
              <Typography variant="body-small" color={XelaColor.Gray7}>{validated} {t('VALIDATED')}</Typography>
              <Typography variant="body-small" color={XelaColor.Gray7}>{awaitingValidation} {t('AWAITING_VALIDATION')}</Typography>
              <Typography variant="body-small" color={XelaColor.Gray7}>{rejected} {t('REJECTED')}</Typography>
            </VStack>
          </VStack>
        </Block>
      </Grid.Col>
    );
  };

  return (
    <Block>
      <Space h={20} />
      <Grid gutter="lg">
        <Grid.Col xs={12}>
          <Typography variant="title3">{t('DASHBOARD')}</Typography>
        </Grid.Col>
      </Grid>
      {
        user?.type === MUNICIPALITY_ADMIN ?
        <Grid gutter="lg">
          {renderMunicipalityAdminCard('EVENTS', municipalityData?.events?.validated || 0, municipalityData?.events?.awaitingValidation || 0, municipalityData?.events?.rejected || 0, 'essentials_appointments', XelaColor.Green12, XelaColor.Green1)}
          {renderMunicipalityAdminCard('ITINERARIES', municipalityData?.itineraries?.validated || 0, municipalityData?.itineraries?.awaitingValidation || 0, municipalityData?.itineraries?.rejected || 0, 'authoring_curvedConnector', XelaColor.Blue12, XelaColor.Blue1)}
          {renderMunicipalityAdminCard('POIS', municipalityData?.pois?.validated || 0, municipalityData?.pois?.awaitingValidation || 0, municipalityData?.pois?.rejected || 0, 'essentials_location', XelaColor.Purple12, XelaColor.Purple1)}
        </Grid>
        :
        <>
          <Grid gutter="lg">
            {renderAdminCard('TOTAL_USERS', adminData?.users || 0, 'essentials_userCircle', XelaColor.Green12, XelaColor.Green1)}
            {renderAdminCard('TOTAL_ITINERARIES', adminData?.itineraries || 0, 'authoring_curvedConnector', XelaColor.Blue12, XelaColor.Blue1)}
            {renderAdminCard('TOTAL_POIS', adminData?.pois || 0, 'essentials_location', XelaColor.Purple12, XelaColor.Purple1)}
          </Grid>
          <Grid gutter="lg">
            <Grid.Col md={6} xs={12} style={{ paddingTop: '20px', paddingBottom: 0 }}>
              <ByGender data={adminData} />
            </Grid.Col>
            <Grid.Col md={6} xs={12} style={{ paddingTop: '20px', paddingBottom: 0 }}>
              <ByCountry data={adminData} />
            </Grid.Col>
          </Grid>
        </>
      }
    </Block>
  );
};

export default DashboardPage;
