import styled from 'styled-components';

export const BlockContent = styled.div<{ color?: string, index: number }>`
  margin-top: ${({ index }) => index > 0 ? '10px' : '0px'};
  position: relative;
  background-color: ${({ color }) => color || '#eef2ff'};
  padding: 10px;
  border-radius: 16px;
`;
export const BlockTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
  color: #000000;
  align-items: center;
`;

export const BlockSection = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

export const BlockSectionText = styled.div`
  margin-left: 5px;
  display: flex;
`;

export const NoStampsText = styled.div`
  font-size: 15px;
  text-align: center;
`;

export const PassportCompleted = styled.div`
  font-size: 16px;
  line-height: 1;

  & span {
    font-weight: bold;
  }
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

export const Totals = styled.div`
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
`;
