import { Block, Grid } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { AlertText } from './styles';
import ImageListInput from '../../../components/inputs/ImageListInput';

interface MultimediaProps {
  subValidate: (fields: string[]) => boolean;
}

const MultimediaTab: FC<MultimediaProps> = () => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <AlertText>
            <span className='red'>{t('NOTE')}: </span>
            <span>{t('POI_IMAGE_ALERT')}</span>
          </AlertText>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col xs={12}>
          <Field name="images">
            {(props) => <ImageListInput {...props} label={t('IMAGES')} />}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default MultimediaTab;
