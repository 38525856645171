import styled from "styled-components";

export const BadgeWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;

  & > div {
    padding: 8px 15px;
  }

  & .xela-typography-caption {
    font-size: 15px;
    line-height: 15px;
  }
`;
