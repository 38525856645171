/* eslint-disable @typescript-eslint/no-explicit-any */
import { RichTextEditor, RichTextEditorProps } from '@mantine/rte';
import { FieldRenderProps } from 'react-final-form';
import { GenericInput, InputWrapper, XelaColor } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useFetch from 'use-http';

const QuillWrapper = styled.div<{ $error: boolean }>`
  .ql-editor,
  .quill {
    min-height: 150px;
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid
      ${({ $error }) => ($error ? XelaColor.Red5 : XelaColor.Gray10)};
  }
  .ql-editor.ql-blank::before {
    color: ${({ $error }) => ($error ? XelaColor.Red5 : XelaColor.Gray10)};
  }
`;

const baseControls: any = [
  ['bold', 'italic', 'underline', 'strike', 'clean'],
  ['h1', 'h2', 'h3', 'h4'],
  ['alignLeft', 'alignCenter', 'alignRight'],
  ['unorderedList', 'orderedList'],
  ['link', 'blockquote', 'codeBlock'],
  ['sup', 'sub']
];

export interface RteTextInputProps
  extends Omit<FieldRenderProps<string>, 'input'>,
    Omit<RichTextEditorProps, 'onChange' | 'value'> {
  input: GenericInput<string>;
  label?: React.ReactNode;
  description?: React.ReactNode;
  allowImages?: boolean;
}

export const RteTextInput: React.FC<RteTextInputProps> = ({
  input,
  meta,
  label,
  description,
  placeholder,
  controls,
  allowImages = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { post } = useFetch(process.env.REACT_APP_IMAGE_URL);
  const hasError = meta?.invalid && meta?.submitFailed;

  const handleImageUpload = useCallback(
    (file: File): Promise<string> =>
      new Promise(async (resolve, reject) => {
        if(allowImages) {
          const formData = new FormData();
          formData.append('image', file);

          const { success, data } = await post('/', formData);
          if(success) resolve(data);
          else reject(new Error(t('IMAGE_UPLOAD_FAILED')));
        }
        else reject(new Error(t('IMAGE_UPLOAD_FAILED')));
      }),
    [allowImages]
  );
  
  return (
    <InputWrapper
      label={label}
      description={description}
      style={{ position: 'relative' }}
    >
      <QuillWrapper $error={hasError}>
        <RichTextEditor
          style={{ borderColor: hasError ? XelaColor.Red5 : XelaColor.Gray10 }}
          controls={controls || (allowImages ? [...baseControls, ['image']] : baseControls)}
          placeholder={placeholder}
          value={input.value || ''}
          onChange={input.onChange}
          onImageUpload={handleImageUpload}
          {...rest}
        />
      </QuillWrapper>
    </InputWrapper>
  );
};

export default RteTextInput;
