import { Block, Grid, MultiSelectInput, SelectInput, SwitchInput } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Category } from '../../../models/Category';
import { Municipality } from '../../../models/Municipality';
import { numberStars, priceIntervals } from '../../../models/Poi';
import { RestaurantLanguages } from '../../../utils/languages/AvailableTranslations';
import { ADMIN, MUNICIPALITY_ADMIN, User } from '../../../models/User';
import TranslatableFields from '../../../components/inputs/TranslatableFields';
import ActiveTranslation, { ActiveTranslationNoHook } from '../../../components/languages/ActiveTranslation';

interface InformationTabProps {
  categories: Category[];
  municipalities: Municipality[];
  subcategories: Category[];
  setSubcategories: (value: Category[]) => void;
  extraFilter: string;
  setExtraFilter: (value: string) => void;
  subValidate: (fields: string[]) => boolean;
  user?: User;
}

const InformationTab: FC<InformationTabProps> = ({
  categories,
  municipalities,
  subValidate,
  subcategories,
  setSubcategories,
  extraFilter,
  setExtraFilter,
  user
}) => {
  const { t, i18n } = useTranslation();

  const checkCategory = (value: string | null, onChange: (value: string | null) => void) => {
    const subList = categories.filter(elem => elem.parent?.toString() === value) || [];
    const category = categories.find(elem => elem._id === value);

    onChange(null);

    setExtraFilter(category?.tag || '');
    setSubcategories(subList || []);
  };

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'NAME',
                name: 'name',
                type: 'text',
                required: true
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'textarea'
              },
              {
                label: 'SCHEDULE',
                name: 'schedule',
                type: 'richtext'
              }
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="municipality">
            {(props) => (
              <SelectInput
                {...props}
                label={t('MUNICIPALITY')}
                placeholder={t('INPUT_MUNICIPALITY_PLACEHOLDER')}
                data={municipalities.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
                searchable
                withAsterisk={user?.type === MUNICIPALITY_ADMIN}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="passport">
            {(props) => (
              <SwitchInput
                {...props}
                label={t('DOURO_PASSPORT')}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="sub_category">
            {({ input: { onChange } }) => (
              <Field name="category">
                {(props) => (
                  <SelectInput
                    {...props}
                    label={t('CATEGORY')}
                    placeholder={t('INPUT_CATEGORY_PLACEHOLDER')}
                    data={categories?.filter(elem => !elem.parent)}
                    translate={(value) => ActiveTranslation({ value })}
                    afterChange={(value: string | null) => checkCategory(value, onChange)}
                    searchable
                  />
                )}
              </Field>
            )}
          </Field>
        </Grid.Col>
        {
          subcategories.length > 0 &&
          <Grid.Col xs={12}>
            <Field name="sub_category">
              {(props) => (
                <SelectInput
                  {...props}
                  label={t('SUB_CATEGORY')}
                  placeholder={t('INPUT_SUB_CATEGORY_PLACEHOLDER')}
                  data={subcategories}
                  translate={(value) => ActiveTranslationNoHook({ t, i18n, value })}
                  clearable
                  searchable
                />
              )}
            </Field>
          </Grid.Col>
        }
        {
          extraFilter === 'WHERE_TO_EAT' ?
          <>
            <Grid.Col xs={12}>
              <Field name="filter_price">
                {(props) => (
                  <SelectInput
                    {...props}
                    label={t('PRICE_INTERVAL')}
                    placeholder={t('INPUT_PRICE_INTERVAL_PLACEHOLDER')}
                    data={priceIntervals}
                    clearable
                  />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12}>
              <Field name="parking">
                {(props) => (
                  <SwitchInput
                    {...props}
                    label={t('PARKING_AVAILABLE')}
                  />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12}>
              <Field name="languages">
                {(props) => (
                  <MultiSelectInput 
                    {...props}
                    label={t('SPOKEN_LANGUAGES')}
                    placeholder={t('INPUT_LANGUAGES_PLACEHOLDER')}
                    searchable
                    data={RestaurantLanguages} 
                    dropdownPosition='flip' 
                  />
                )}
              </Field>
            </Grid.Col>
            <Grid.Col xs={12}>
              <TranslatableFields
                subValidate={subValidate}
                fields={[
                  {
                    label: 'DELICACIES',
                    name: 'delicacies',
                    type: 'textarea'
                  }
                ]}
              />
            </Grid.Col>
          </>
          :
          extraFilter === 'WHERE_TO_SLEEP' ?
          <Grid.Col xs={12}>
            <Field name="filter_stars">
              {(props) => (
                <SelectInput
                  {...props}
                  label={t('NUMBER_STARS')}
                  placeholder={t('INPUT_NUMBER_STARS_PLACEHOLDER')}
                  data={numberStars}
                  clearable
                />
              )}
            </Field>
          </Grid.Col>
          :
          null
        }
      </Grid>
    </Block>
  );
};

export default InformationTab;
