import {
  GenericInput,
  Grid,
  InputWrapper,
  NumberInput
} from '@codepoint-pt/xela';
import { TextInput } from '@mantine/core';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { FC, useEffect, useState } from 'react';
import { FieldMetaState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Coordinates, MapState } from '../../models/Maps';

interface MapInputProps {
  label: string;
  latitudeLabel: string;
  latitudePlaceholder: string;
  longitudeLabel: string;
  longitudePlaceholder: string;
  input: GenericInput<Coordinates>;
  meta: FieldMetaState<Coordinates>;
  withAsterisk?: boolean;
}

const latitudeDefault = 38.01737;
const longitudeDefault = -7.86536;

const MapInput: FC<MapInputProps> = ({
  label,
  input,
  meta,
  latitudeLabel,
  longitudeLabel,
  withAsterisk = true
}) => {
  const { t } = useTranslation();

  const [mapState, setMapState] = useState<MapState>({
    zoom: 10,
    latitude: latitudeDefault,
    longitude: longitudeDefault
  });

  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined);

  const truncateNumber = (number: number) => {
    return Math.trunc(number * 1000000) / 1000000;
  };

  const handleDrag = (event: google.maps.MapMouseEvent) => {
    const result = {
      latitude: truncateNumber(event?.latLng?.lat() || latitudeDefault),
      longitude: truncateNumber(event?.latLng?.lng() || longitudeDefault)
    };
    input.onChange(result);
  };

  const handleInputChange = (key: 'latitude' | 'longitude', value: number) => {
    const result = { ...input.value };
    result[key] = value;
    input.onChange(result);
  };

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== undefined) {
      const local = autocomplete.getPlace();

      if (local?.geometry?.location) {
        const result = {
          latitude: truncateNumber(local?.geometry?.location.lat()),
          longitude: truncateNumber(local?.geometry?.location.lng())
        };

        input.onChange(result);
      }
    }
  };

  useEffect(() => {
    setMapState({
      ...mapState,
      latitude: truncateNumber(input.value?.latitude || latitudeDefault),
      longitude: truncateNumber(input.value?.longitude || longitudeDefault)
    });
  }, [input.value]);

  return (
    <InputWrapper label={label}>
      <GoogleMap
        id="poi"
        mapContainerStyle={{ width: '100%', height: '400px' }}
        zoom={mapState.zoom}
        options={{ minZoom: 3 }}
        center={{
          lat: parseFloat(mapState.latitude.toString()),
          lng: parseFloat(mapState.longitude.toString())
        }}
      >
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <TextInput
            style={{ position: 'absolute', left: 0, bottom: 0, width: 350 }}
            type="text"
            placeholder={t('SEARCH_FOR_A_PLACE')}
          />
        </Autocomplete>
        <Marker
          draggable={true}
          onDragEnd={handleDrag}
          position={{
            lat: parseFloat(mapState.latitude.toString()),
            lng: parseFloat(mapState.longitude.toString())
          }}
        />
      </GoogleMap>
      <Grid gutter={12} style={{ marginTop: 10 }}>
        <Grid.Col xs={6}>
          <NumberInput
            label={latitudeLabel}
            input={{
              value: mapState.latitude || undefined,
              onChange: (value: string | null) =>
                handleInputChange(
                  'latitude',
                  parseFloat(value || latitudeDefault.toString())
                )
            }}
            meta={{ ...meta, invalid: !!meta?.error?.latitude }}
            precision={6}
            step={1}
            withAsterisk={withAsterisk}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <NumberInput
            label={longitudeLabel}
            input={{
              value: mapState.longitude || undefined,
              onChange: (value: string | null) =>
                handleInputChange(
                  'longitude',
                  parseFloat(value || longitudeDefault.toString())
                )
            }}
            meta={{ ...meta, invalid: !!meta?.error?.longitude }}
            precision={6}
            step={1}
            withAsterisk={withAsterisk}
          />
        </Grid.Col>
      </Grid>
    </InputWrapper>
  );
};

export default MapInput;
