import { FC } from 'react';
import { AnyObject } from '../../models/Generic';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '../../hooks/show-notification/show-notification';
import { Typography, Icon, HStack } from '@codepoint-pt/xela';
import DropdownMenu, { ActionProps } from '../dropdownMenu';

interface LabelDropdownProps {
  status: string;
  list: AnyObject[];
  id: string;
  onConfirmStatusToogle: (id: string, status: string) => void;
  disabled?: boolean;
}

export const LabelDropdown: FC<LabelDropdownProps> = ({ status, list, id, onConfirmStatusToogle, disabled = false }) => {
  const { t } = useTranslation();
  
  const renderStatus = () => {
    const statusDetail = list.find(elem => elem._id === status);

    if(!statusDetail) return null;

    return (
      <HStack borderRadius='100px' alignItems='center' spacing='8px' style={{ padding: '5px 12px',  backgroundColor: statusDetail.bgColor }}>
        <Typography variant='body-small' style={{ color: statusDetail.fontColor }}>{t(statusDetail.name)}</Typography>
        {!disabled && <Icon icon='basics_down' color={statusDetail.fontColor} size={10} /> }
      </HStack>
    );
  };

  const renderStatusActions = () => {
    if(disabled) return [];
    
    const filtered = list.filter(elem => elem._id !== status);

    if(filtered.length > 0) {
      const result: ActionProps[] = [];

      filtered.forEach((elem: AnyObject, index: number) => {
        result.push({
          type: 'link',
          label: t(elem.name),
          onClick: () => handleStatusToogle(elem.name)
        });

        if(index < (filtered.length - 1)) {
          result.push({
            type: 'divider'
          });
        }
      });

      return result;
    }
    return [];
  };

  const handleStatusToogle = async (status: string) => {
    showConfirm({
      title: t('CHANGE_STATUS'),
      message: t('CHANGE_STATUS_MESSAGE').replace('#STATUS#', t(status).toUpperCase()),
      onConfirm: () => onConfirmStatusToogle(id, status)
    });
  };

  return (
    <DropdownMenu 
      render={renderStatus()}
      menuPosition='bottom-end'
      actions={renderStatusActions()} 
    />
  );
};

export default LabelDropdown;
