import { BaseTable, DefaultListPage, Grid, Icon, SearchInput, SelectInput, TableColumnsProps } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Category } from '../../models/Category';
import { AnyObject } from '../../models/Generic';
import { Municipality } from '../../models/Municipality';
import { Poi, PoiStatusList } from '../../models/Poi';
import { useContext } from 'react';
import { InfoContext, UserContext } from '../../Context';
import { ADMIN, MUNICIPALITY_ADMIN } from '../../models/User';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import LabelDropdown from '../../components/labelDropdown';

const PassportOptions = [
  {
    _id: 'true',
    name: 'YES'
  },
  {
    _id: 'false',
    name: 'NO'
  }
];

const PoisListPage = DefaultListPage<Poi>();
const PoisTable = BaseTable<Poi>();

const PoisPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const adminHook = useFetch('/pois');
  const municipalityHook = useFetch('/pois/municipality');

  return (
    <PoisListPage
      translate={t}
      request={{
        get: user?.type === MUNICIPALITY_ADMIN ? municipalityHook.get : adminHook.get,
        searchFields: ['name|intl', 'description|intl']
      }}
      storage="POIS_FILTERS"
      header={{
        navigate,
        title: t('POIS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/pois/new')
          }
        ],
        breadcrumbs: [{ title: t('POIS'), href: '/pois' }]
      }}
      filters={({ filters, handleChange, setFilters }) => {
        const checkCategory = async (value: string | null) => {
          if(!!setFilters && !value) setFilters({ ...filters, category: null, sub_category: null });
        };

        const subcategories = info?.categories?.filter(elem => !!elem.parent && elem.parent === filters.category) || [];

        return (
          <Grid gutter={24}>
            <Grid.Col xs={6}>
              <SearchInput
                input={{
                  value: filters.search,
                  onChange: (v: string | null) => handleChange('search', v, 1000)
                }}
                onSearch={(v) => handleChange('search', v)}
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <SelectInput 
                placeholder={t('SELECT_CATEGORY')}
                input={{
                  value: filters.category,
                  onChange: (v: string | null) => { handleChange('category', v, 0); }
                }}
                data={info?.categories?.filter(elem => !elem.parent)}
                translate={(value) => ActiveTranslation({ value })}
                clearable
                afterChange={checkCategory}
              />
            </Grid.Col>
            {
              subcategories.length > 0 &&
              <Grid.Col xs={3}>
                <SelectInput 
                  placeholder={t('SELECT_SUB_CATEGORY')}
                  input={{
                    value: filters.sub_category,
                    onChange: (v: string | null) => handleChange('sub_category', v, 0)
                  }}
                  data={subcategories}
                  translate={(value) => ActiveTranslation({ value })}
                  clearable
                />
              </Grid.Col>
            }
            <Grid.Col xs={3}>
              <SelectInput 
                placeholder={t('SELECT_MUNICIPALITY')}
                input={{
                  value: filters.municipality,
                  onChange: (v: string | null) => handleChange('municipality', v, 1000)
                }}
                data={info?.municipalities?.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
                clearable
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <SelectInput 
                placeholder={t('DOURO_PASSPORT')}
                input={{
                  value: filters.passport,
                  onChange: (v: string | null) => handleChange('passport', v, 1000)
                }}
                data={PassportOptions}
                translate={t}
                clearable
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <SelectInput 
                placeholder={t('SELECT_STATUS')}
                input={{
                  value: filters.status,
                  onChange: (v: string | null) => handleChange('status', v, 1000)
                }}
                data={PoiStatusList}
                translate={t}
                clearable
              />
            </Grid.Col>
          </Grid>
        )
      }}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await adminHook.put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };

        const columns: TableColumnsProps<Poi>[] = [
          {
            title: t('NAME'),
            dataIndex: 'name',
            sortable: true,
            render: (value: AnyObject) => <ActiveTranslation value={value || 'N/A'} />
          },
          {
            title: t('CATEGORY'),
            dataIndex: 'category',
            sortable: true,
            render: (value: Category) => (
              <ActiveTranslation value={value?.name || 'N/A'} />
            )
          },
          {
            title: t('SUB_CATEGORY'),
            dataIndex: 'sub_category',
            sortable: true,
            render: (value: Category) => (
              <ActiveTranslation value={value?.name || 'N/A'} />
            )
          },
          {
            title: t('MUNICIPALITY'),
            dataIndex: 'municipality',
            sortable: true,
            render: (value: Municipality) => (
              <ActiveTranslation value={value?.name || 'N/A'} />
            )
          },
          {
            title: t('DOURO_PASSPORT'),
            dataIndex: 'passport',
            sortable: true,
            render: (value: boolean) => value ? t('YES') : t('NO')
          },
          {
            title: t('STATUS'),
            dataIndex: 'status',
            render: (value: string, poi: Poi) => (
              <LabelDropdown
                status={value}
                list={PoiStatusList}
                id={poi._id}
                onConfirmStatusToogle={onConfirmStatusToogle}
                disabled={user?.type !== ADMIN}
              />
            )
          }
        ];

        return (
          <PoisTable
            columns={columns}
            rows={rows}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
            onRowClick={(row) => navigate(`/pois/${row._id}`)}
          />
        );
      }}
    </PoisListPage>
  );
};

export default PoisPage;
