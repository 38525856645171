import { BaseTable, DateInput, DefaultListPage, Grid, Icon, SearchInput, SelectInput, TableColumnsProps } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../../models/Generic';
import { Municipality } from '../../models/Municipality';
import { Event, EventStatusList } from '../../models/Event';
import { InfoContext, UserContext } from '../../Context';
import { useContext } from 'react';
import { ADMIN, MUNICIPALITY_ADMIN } from '../../models/User';
import dayjs from 'dayjs';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import LabelDropdown from '../../components/labelDropdown';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';

const EventsListPage = DefaultListPage<Event>();
const EventsTable = BaseTable<Event>();

const EventsPage = () => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const adminHook = useFetch('/events');
  const municipalityHook = useFetch('/events/municipality');
  
  return (
    <EventsListPage
      translate={t}
      request={{
        get: user?.type === MUNICIPALITY_ADMIN ? municipalityHook.get : adminHook.get,
        searchFields: ['title|intl', 'description|intl']
      }}
      storage="EVENTS_FILTERS"
      header={{
        navigate,
        title: t('EVENTS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/events/new')
          }
        ],
        breadcrumbs: [{ title: t('EVENTS'), href: '/events' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={6}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <SelectInput
              placeholder={t('SELECT_MUNICIPALITY')}
              input={{
                value: filters.municipality,
                onChange: (v: string | null) => handleChange('municipality', v, 1000)
              }}
              data={info?.municipalities?.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <SelectInput 
              placeholder={t('SELECT_STATUS')}
              input={{
                value: filters.status,
                onChange: (v: string | null) => handleChange('status', v, 1000)
              }}
              data={EventStatusList}
              translate={t}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <DateInput 
              placeholder={t('SELECT_START_DATE')}
              input={{
                value: filters.start_date,
                onChange: (v: string | null) => handleChange('start_date', v, 1000)
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <DateInput 
              placeholder={t('SELECT_END_DATE')}
              input={{
                value: filters.end_date,
                onChange: (v: string | null) => handleChange('end_date', v, 1000)
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_INTEREST')}
              input={{
                value: filters.interest,
                onChange: (v: string | null) => handleChange('interest', v, 1000)
              }}
              data={info.interests}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_CATEGORY')}
              input={{
                value: filters.category,
                onChange: (v: string | null) => handleChange('category', v, 1000)
              }}
              data={info.eventCategories}
              clearable
            />
          </Grid.Col>
        </Grid>
      )}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await adminHook.put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };
      
        const columns: TableColumnsProps<Event>[] = [
          {
            title: t('TITLE'),
            dataIndex: 'title',
            sortable: true,
            render: (value: AnyObject) => <ActiveTranslation value={value || 'N/A'} />
          },
          {
            title: t('FROM_DATE'),
            dataIndex: 'start_datetime',
            sortable: true,
            render: (value: string) => {
              const startDate = dayjs.utc(value);
              const startYear = startDate.year();
              const startMonth = startDate.month();
              const startDay = startDate.date();
              const startHour = startDate.hour();
              const startMinute = startDate.minute();
              const startDateObject = dayjs().year(startYear).month(startMonth).date(startDay).hour(startHour).minute(startMinute).second(0).millisecond(0);

              return (
                value ? dayjs(startDateObject).format('DD/MM/YYYY, HH:mm') : ''
              );
            }
          },
          {
            title: t('TO_DATE'),
            dataIndex: 'end_datetime',
            sortable: true,
            render: (value: string) => {
              const endDate = dayjs.utc(value);
              const endYear = endDate.year();
              const endMonth = endDate.month();
              const endDay = endDate.date();
              const endHour = endDate.hour();
              const endMinute = endDate.minute();
              const endDateObject = dayjs().year(endYear).month(endMonth).date(endDay).hour(endHour).minute(endMinute).second(0).millisecond(0);

              return (
                value ? dayjs(endDateObject).format('DD/MM/YYYY, HH:mm') : ''
              );
            }
          },
          {
            title: t('MUNICIPALITY'),
            dataIndex: 'municipality',
            sortable: true,
            render: (value: Municipality) => (
              <ActiveTranslation value={value?.name || 'N/A'} />
            )
          },
          {
            title: t('STATUS'),
            dataIndex: 'status',
            render: (value: string, event: Event) => (
              <LabelDropdown
                status={value}
                list={EventStatusList}
                id={event._id}
                onConfirmStatusToogle={onConfirmStatusToogle}
                disabled={user?.type !== ADMIN}
              />
            )
          }
        ];

        return (
          <EventsTable
            columns={columns}
            rows={rows}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
            onRowClick={(row) => navigate(`/events/${row._id}`)}
          />
        );
      }}
    </EventsListPage>
  );
};

export default EventsPage;
