/* eslint-disable @typescript-eslint/no-explicit-any */
import { AdminTypesList, User } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { DefaultListPage, Grid, Icon, SearchInput, Typography } from '@codepoint-pt/xela';
import { useNavigate } from 'react-router-dom';
import { InfoContext } from '../../Context';
import { useContext } from 'react';
import dayjs from 'dayjs';
import useFetch from 'use-http';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';

const AdminListPage = DefaultListPage<User>();

const AdminsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { get } = useFetch('/admins');
  const { info } = useContext(InfoContext);

  const columns = [
    { 
      title: t('NAME'), 
      dataIndex: 'name', 
      sortable: true 
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      sortable: true
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      sortable: true,
      render: (value: string) => <Typography variant="caption">{t(value)}</Typography>
    },
    {
      title: t('MUNICIPALITY'),
      dataIndex: 'municipalities',
      sortable: true,
      render: (values: string[]) => renderMunicipalities(values)
    },
    {
      title: t('LAST_LOGIN'),
      dataIndex: 'latest_login',
      render: (value: string) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : t('NO_RECORD')
    }
  ];

  const renderMunicipalities = (values: string[]) => {
    let result = '';

    if(info.municipalities?.length > 0 && values?.length > 0) {
      values.forEach(elem => {
        const municipality = info.municipalities.find(municipality => municipality._id === elem);

        if(municipality) result = `${result}${municipality.name}, `;
      });

      result = result.slice(0, -2);
    }

    if(!result) result = 'N/A';

    return <Typography variant="caption">{result}</Typography>;
  };

  return (
    <AdminListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email']
      }}
      storage="ADMIN_FILTERS"
      header={{
        navigate,
        title: t('ADMINISTRATORS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/admins/new')
          }
        ],
        breadcrumbs: [{ title: t('ADMINISTRATORS'), href: '/admins' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <IntlSelectInput
              input={{
                value: filters.type || '',
                onChange: (v: string | null) => handleChange('type', v, 1000)
              }}
              placeholder={t('CHOOSE_TYPE')}
              clearable
              data={AdminTypesList}
              translatable
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <IntlSelectInput
              input={{
                value: filters.municipality || '',
                onChange: (v: string | null) => handleChange('municipality', v, 1000)
              }}
              placeholder={t('CHOOSE_MUNICIPALITY')}
              clearable
              data={info.municipalities}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/admins/${row._id}`)
      }}
    />
  );
};

export default AdminsPage;
