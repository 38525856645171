import { AnyObject, DefaultListPage, Grid, Icon, SearchInput, SelectInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Itinerary } from '../../models/Itinerary';
import { InfoContext, UserContext } from '../../Context';
import { useContext, useEffect, useState } from 'react';
import { Poi } from '../../models/Poi';
import { ADMIN, MUNICIPALITY_ADMIN } from '../../models/User';
import { Municipality } from '../../models/Municipality';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';

const ItinerariesListPage = DefaultListPage<Itinerary>();

const ItinerariesPage = () => {
  const [pois, setPois] = useState<Poi[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const adminHook = useFetch('/itineraries');
  const municipalityHook = useFetch('/itineraries/municipality');
  const { get: getPois } = useFetch('/pois/dropdown');
  
  useEffect(() => {
    const init = async () => {
      const { data, success } = await getPois();
      if(success) setPois(data);
    };

    init();
  }, []);

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    },
    {
      title: t('MUNICIPALITY'),
      dataIndex: 'municipality',
      sortable: true,
      render: (value: Municipality) => <ActiveTranslation value={value?.name || 'N/A'} />
    },
    {
      title: t('POINTS_OF_INTEREST'),
      dataIndex: 'totalPoints',
      sortable: true
    },
    {
      title: t('PEOPLE'),
      dataIndex: 'totalPeople',
      sortable: true
    },
    {
      title: t('DAYS'),
      dataIndex: 'totalDays',
      sortable: true
    },
    {
      title: t('KM'),
      dataIndex: 'totalKm',
      sortable: true
    },
    {
      title: t('TOTAL_DOWNLOADS'),
      dataIndex: 'totalDownloads',
      sortable: true,
      render: (value: number) => value || 0
    }
  ];

  return (
    <ItinerariesListPage
      translate={t}
      request={{
        get: user?.type === MUNICIPALITY_ADMIN ? municipalityHook.get : adminHook.get,
        searchFields: ['name|intl', 'description|intl']
      }}
      storage="ITINERARY_FILTERS"
      header={{
        navigate,
        title: t('ITINERARIES'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/itineraries/new')
          }
        ],
        breadcrumbs: [{ title: t('ITINERARIES'), href: '/itineraries' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={3}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_INTEREST')}
              input={{
                value: filters.interest,
                onChange: (v: string | null) => handleChange('interest', v, 1000)
              }}
              data={info.interests}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_POI')}
              input={{
                value: filters.poi,
                onChange: (v: string | null) => handleChange('poi', v, 1000)
              }}
              data={pois}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
              <SelectInput 
                placeholder={t('SELECT_MUNICIPALITY')}
                input={{
                  value: filters.municipality,
                  onChange: (v: string | null) => handleChange('municipality', v, 1000)
                }}
                data={info?.municipalities?.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
                clearable
              />
            </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/itineraries/${row._id}`)
      }}
    />
  );
};

export default ItinerariesPage;
