import { AspectRatios, Badge, Block, Grid, ImageInput, NumberInput, SelectInput, XelaColor } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { InfoContext } from '../../../Context';
import { useContext } from 'react';
import { ADMIN, MUNICIPALITY_ADMIN, User } from '../../../models/User';
import { Municipality } from '../../../models/Municipality';
import TranslatableFields from '../../../components/inputs/TranslatableFields';
import IntlMultipleSelectInput from '../../../components/inputs/IntlMultipleSelectInput';
import { Itinerary } from '../../../models/Itinerary';
import { BadgeWrapper } from '../styles';

interface Props {
  subValidate: (fields: string[]) => boolean;
  user?: User;
  municipalities: Municipality[];
  initialValues?: Itinerary;
}

const InformationTab = ({ subValidate, user, municipalities, initialValues }: Props) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={6} offset={3}>
          <Field name="photo">
            {(props) => (
              <ImageInput
                {...props}
                label={t('PHOTO')}
                ratio={AspectRatios['16:9']}
              />
            )}
          </Field>
        </Grid.Col>
        {
          initialValues?._id &&
          <Grid.Col xs={12}>
            <BadgeWrapper>
              <Badge label={`${t('TOTAL_DOWNLOADS')}: ${initialValues?.totalDownloads || 0}`} bg={XelaColor.Indigo7} />
            </BadgeWrapper>
          </Grid.Col>
        }
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'NAME',
                name: 'name',
                type: 'text',
                required: true
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'richtext'
              }
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="municipality">
            {(props) => (
              <SelectInput
                {...props}
                label={t('MUNICIPALITY')}
                placeholder={t('INPUT_MUNICIPALITY_PLACEHOLDER')}
                data={municipalities.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
                searchable
                withAsterisk={user?.type === MUNICIPALITY_ADMIN}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="totalPeople">
            {props => (
              <NumberInput
                {...props}
                label={t('NUMBER_PEOPLE')}
                placeholder={t('INPUT_NUMBER_PEOPLE_PLACEHOLDER')}
                type='number'
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="interests">
            {(props) => (
              <IntlMultipleSelectInput
                {...props}
                label={t('INTERESTS')}
                placeholder={t('INPUT_INTERESTS_PLACEHOLDER')}
                data={info.interests}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
