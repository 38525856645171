import { XelaColor } from '@codepoint-pt/xela';
import { Country } from './Country';
import { Event } from './Event';
import { AnyObject } from './Generic';
import { Interest } from './Interest';
import { Itinerary } from './Itinerary';
import { Poi } from './Poi';

// Link types
export const POI = 'POI';
export const POIS = 'POIS';
export const EVENT = 'EVENT';
export const EVENTS = 'EVENTS';
export const ITINERARY = 'ITINERARY';
export const ITINERARIES = 'ITINERARIES';
export const LINK = 'LINK';

type LinkTypes = typeof POI | typeof POIS | typeof EVENT | typeof EVENTS | typeof ITINERARY | typeof ITINERARIES | typeof LINK;

export const LinkTypesList = [
  { _id: POI, name: POI },
  { _id: POIS, name: POIS },
  { _id: EVENT, name: EVENT },
  { _id: EVENTS, name: EVENTS },
  { _id: ITINERARY, name: ITINERARY },
  { _id: ITINERARIES, name: ITINERARIES },
  { _id: LINK, name: LINK }
];

// State types
export const NOT_SENT = 'NOT_SENT';
export const SENDING = 'SENDING';
export const SENT = 'SENT';

export type StatusTypes = typeof NOT_SENT | typeof SENDING | typeof SENT;

export const StatusList = [
  { _id: NOT_SENT, name: NOT_SENT, color: XelaColor.Red3 },
  { _id: SENDING, name: SENDING, color: XelaColor.Yellow2 },
  { _id: SENT, name: SENT, color: XelaColor.Green1 }
];

// Notification model
interface State {
  status: StatusTypes;
  start_date: Date;
  end_date: Date;
}

export interface Notification {
  readonly _id: string;
  country?: Country;
  interest?: Interest;
  text: AnyObject;
  link_type: LinkTypes;
  poi?: Poi;
  event?: Event;
  itinerary?: Itinerary;
  link?: string;
  state: State;
}
