/* eslint-disable no-console */
import { AuthTokenKey } from '../requests/LocalStorageKeys';

const GetFile = (url: string, defaultFilename?: string, toggleLoading?: (value: boolean) => void) => {
  const authToken = localStorage.getItem(AuthTokenKey);
  let filename = defaultFilename || 'file';

  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
  .then((resp) => {
    const headerFile = resp?.headers?.get('Content-Disposition');
    if(headerFile) {
      const parts = headerFile.split('=');
      filename = parts[1];
    }

    return resp.blob();
  })
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    if(!!toggleLoading) toggleLoading(false);
  })
  .catch((e) => {
    if(!!toggleLoading) toggleLoading(false);
    console.log(e);
  })
}

export default GetFile;
