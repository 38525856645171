import { HeaderBaseAction, Icon, LoadingOverlay, PageHeader, XelaColor, toFormData } from '@codepoint-pt/xela';
import { useContext, useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { InfoContext, UserContext } from '../../Context';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../styles/BasicStyles';
import { ValidationErrors } from 'final-form';
import { Event } from '../../models/Event';
import { Interest } from '../../models/Interest';
import { AnyObject } from '../../models/Generic';
import { ADMIN, MUNICIPALITY_ADMIN } from '../../models/User';
import { useLocation } from 'react-router-dom';
import { REJECTED, EVENT, ITINERARY, POI } from '../../models/Draft';
import { showConfirm, showSuccess } from '../../hooks/show-notification/show-notification';
import { renderAlert, renderStatus } from '../../utils/draft';
import yup, { isCoordinate, isMongoId, isSlug, isUrl } from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import InformationTab from './tabs/InformationTab';
import MapTab from './tabs/MapTab';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import MetaDataTab from '../../components/metadata';
import RejectModal from '../drafts/components/RejectModal';
import MultimediaTab from './tabs/MultimediaTab';
import dayjs from 'dayjs';

const { Form } = withTypes<Event>();

const ManageEventPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Event>();
  const [rejectModal, setRejectModal] = useState<{ open: boolean; type?: typeof EVENT | typeof ITINERARY | typeof POI; title?: string; id?: string; }>({ open: false });
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const intlObject = useIntlValidation();
  const eventHook = useFetch('/events');
  const draftHook = useFetch('/drafts/event');
  const isDraft = location.pathname.includes('/drafts/event/');

  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = async () => {
    if(id) {
      const { data, success } = isDraft ? await draftHook.get(`/${id}`) : await eventHook.get(`/${id}`);
      
      if(success) {
        if(user?.type === MUNICIPALITY_ADMIN && !user?.municipalities?.includes(data?.municipality?._id)) return navigate(isDraft ? '/drafts' : '/events');

        const startDate = dayjs.utc(data.start_datetime);
        const startYear = startDate.year();
        const startMonth = startDate.month();
        const startDay = startDate.date();
        const startHour = startDate.hour();
        const startMinute = startDate.minute();
        const startDateObject = dayjs().year(startYear).month(startMonth).date(startDay).hour(startHour).minute(startMinute).second(0).millisecond(0);

        const endDate = dayjs.utc(data.end_datetime);
        const endYear = endDate.year();
        const endMonth = endDate.month();
        const endDay = endDate.date();
        const endHour = endDate.hour();
        const endMinute = endDate.minute();
        const endDateObject = dayjs().year(endYear).month(endMonth).date(endDay).hour(endHour).minute(endMinute).second(0).millisecond(0);
        
        setInitialValues({ 
          ...data, 
          start_datetime: startDateObject,
          end_datetime: endDateObject, 
          municipality: data?.municipality?._id, 
          interests: data.interests?.map((elem: Interest) => elem._id),
          category: data?.category?._id
        });
      }
    }

    setLoading(false);
  };

  const onSubmit = async (values: Event) => {
    let success = false;
    
    const startDate = dayjs(values.start_datetime);
    const startYear = startDate.year();
    const startMonth = startDate.month();
    const startDay = startDate.date();
    const startHour = startDate.hour();
    const startMinute = startDate.minute();
    const startDateObject = new Date(Date.UTC(startYear, startMonth, startDay, startHour, startMinute, 0));

    const endDate = dayjs(values.end_datetime);
    const endYear = endDate.year();
    const endMonth = endDate.month();
    const endDay = endDate.date();
    const endHour = endDate.hour();
    const endMinute = endDate.minute();
    const endDateObject = new Date(Date.UTC(endYear, endMonth, endDay, endHour, endMinute, 0));

    values.start_datetime = startDateObject;
    values.end_datetime = endDateObject;

    // origin_event is only needed when a Municipality Admin edits a production event, we need to save that event ID to link the event and draft
    const payload = isDraft ? toFormData(values) : toFormData({ ...values, origin_event: id });

    if(id) {
      let result = null;

      // If the user is a Municipality Admin, when trying to edit a production event, we need to create a Event Draft instead
      if(user?.type === MUNICIPALITY_ADMIN && !isDraft) {
        result = await draftHook.post(payload);
      }
      else {
        result = isDraft ? await draftHook.put(`/${values._id}`, payload) : await eventHook.put(`/${values._id}`, payload);
      }

      success = result.success;
    } 
    else {
      const result = user?.type === MUNICIPALITY_ADMIN ? await draftHook.post(payload) : await eventHook.post(payload);
      success = result.success;
    }

    if(success) {
      /* 
      * Logic explained:
      * IF USER IS MUNICIPALITY ADMIN
      *   - New event draft OR edit production event » /drafts?success
      *   - Edit draft event » /drafts
      * 
      * IF USER IS ADMIN
      *   - New or edit draft event » /drafts
      *   - New or edit production event » /events
      */

      if(user?.type === MUNICIPALITY_ADMIN) {
        if(id && isDraft) navigate('/drafts');
        else navigate('/drafts?success');
      }
      else {
        if(isDraft) navigate('/drafts');
        else navigate('/events');
      }
    }
  };

  const onDelete = async () => {
    if(id) {
      setLoading(true);

      const { success } = isDraft ? await draftHook.del(`/${id}`) : await eventHook.del(`/${id}`);
      if(success) navigate(isDraft ? '/drafts' : '/events');
      else setLoading(false);
    }
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean, values: AnyObject) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: (
          <InformationTab
            municipalities={info?.municipalities}
            interests={info?.interests}
            eventCategories={info?.eventCategories}
            subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)}
            user={user}
          />
        ),
        error: useSectionValidation(
          errors,
          ['title'],
          submitFailed
        )
      },
      {
        value: 'multimedia',
        label: t('MULTIMEDIA'),
        children: (
          <MultimediaTab
            subValidate={(fields) =>
              useSectionValidation(errors, fields, submitFailed)
            }
          />
        )
      },
      {
        value: 'coordinates',
        label: t('COORDINATES'),
        children: <MapTab />
      },
      {
        value: 'metadata',
        label: t('METADATA'),
        children: <MetaDataTab subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)} values={values} section='event' />,
        error: useSectionValidation(
          errors,
          ['slug'],
          submitFailed
        )
      }
    ];

    return tabs;
  };

  const renderTitle = () => {
    if(id) {
      if(isDraft) return t('EDIT_DRAFT_EVENT');
      else return t('EDIT_EVENT');
    }
    else {
      if(user?.type === MUNICIPALITY_ADMIN) return t('ADD_DRAFT_EVENT');
      else return t('ADD_EVENT');
    }
  };

  const validateEvent = () => {
    showConfirm({
      title: t('CONFIRM'),
      message: t('VALIDATE_EVENT'),
      onConfirm: async () => {
        setLoadingValidate(true);

        const { success, data } = await draftHook.put(`/${id}/validate`);

        if(success && data?._id) {
          showSuccess({
            title: t('SUCCESS'),
            message: t('EVENT_VALIDATED_MESSAGE')
          });

          navigate(`/events/${data._id}`);
        }

        setLoadingValidate(false);
      }
    });
  };

  const handleCloseRejectModal = (changed: boolean) => {
    if(changed) {
      setLoading(true);
      getInfo();
    }
    setRejectModal({ open: false });
  };

  const renderValidateActions = () => {
    const extra: HeaderBaseAction[] = [];

    if(user?.type === ADMIN && isDraft) {
      extra.push({
        name: t('VALIDATE'),
        onClick: validateEvent,
        action: 'secondary',
        color: 'green',
        variant: 'light',
        icon: <Icon icon="basics_tickCircle" size={12} color={XelaColor.Green1} />,
        loading: loadingValidate,
        disabled: loadingValidate
      });

      if(initialValues?.status !== REJECTED) {
        extra.push({
          name: t('REJECT'),
          onClick: () => setRejectModal({ open: true, type: EVENT, title: initialValues?.title[language], id: initialValues?._id }),
          action: 'secondary',
          color: 'orange',
          variant: 'light',
          icon: <Icon icon="basics_xCircle" size={12} color={XelaColor.Orange4} />,
          disabled: loadingValidate
        });
      }
    }

    return extra;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={useValidationSchema(
          yup.object({
            title: intlObject({ required: true }).required(),
            start_datetime: yup.date().required(),
            end_datetime: yup.date().required(),
            website: isUrl,
            municipality: user?.type === MUNICIPALITY_ADMIN ? isMongoId.required() : isMongoId.nullable(),
            coordinates: yup.object({ latitude: isCoordinate, longitude: isCoordinate }),
            slug: isSlug.required(),
            category: isMongoId.required(),
            photo: yup.mixed().required()
          })
        )}
      >
        {({ values, handleSubmit, submitting, pristine, errors, submitFailed }) => (
          <BasicForm onSubmit={handleSubmit}>
            <LoadingOverlay visible={loading} />
            <FormPrompt
              when={!pristine && !submitting && !loading}
              message={t('PROMPT_MESSAGE')}
            />
            <PageHeader
              navigate={navigate}
              title={renderTitle()}
              onBack={() => navigate(isDraft ? '/drafts' : '/events')}
              breadcrumbs={[
                { title: t('EVENTS'), href: '/events' },
                { title: t(id ? 'EDIT' : 'ADD') }
              ]}
              actions={headerActions({
                submitting,
                pristine,
                backLink: isDraft ? '/drafts' : '/events',
                onDelete: user?.type === ADMIN && id ? onDelete : undefined,
                extra: renderValidateActions(),
                save: !isDraft && initialValues?.draft?._id ? false : true
              })}
            />
            { user?.type === ADMIN && !isDraft && initialValues?.draft?._id && renderAlert(t, navigate, 'EVENT_HAS_DRAFT_ADMIN', `/drafts/event/${initialValues?.draft?._id}`) }
            { user?.type === MUNICIPALITY_ADMIN && !isDraft && initialValues?.draft?._id && renderAlert(t, navigate, 'EVENT_HAS_DRAFT', `/drafts/event/${initialValues?.draft?._id}`) }
            { isDraft && renderStatus(t, initialValues?.status) }
            <BaseTabs
              baseTab="information"
              tabs={defineTabs(errors, submitFailed, values)}
            />
          </BasicForm>
        )}
      </Form>
      <RejectModal
        opened={rejectModal.open}
        type={rejectModal.type}
        title={rejectModal.title}
        id={rejectModal.id}
        onClose={handleCloseRejectModal}
      />
    </>
  );
};

export default ManageEventPage;
