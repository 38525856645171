import { HeaderBaseAction, Icon, XelaColor } from '@codepoint-pt/xela';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { showDelete } from '../../hooks/show-notification/show-notification';

interface ActionProps {
  submitting: boolean;
  pristine: boolean;
  onDelete?: (() => Promise<void>) | (() => void);
  deleteTitle?: string;
  deleteMessage?: string;
  backLink?: string;
  extra?: HeaderBaseAction[];
  save?: boolean;
}

type HeaderActionsFunction = (props: ActionProps) => HeaderBaseAction[];

const headerActions: HeaderActionsFunction = ({
  submitting,
  pristine,
  onDelete,
  deleteTitle = 'DELETE_ENTITY',
  deleteMessage = 'DELETE_ENTITY_MESSAGE',
  backLink,
  extra = [],
  save = true
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const base: HeaderBaseAction[] = [...extra];

  if(save) {
    base.push({
      name: t('SAVE'),
      variant: 'outline',
      type: 'submit',
      loading: submitting,
      disabled: pristine,
      icon: <Icon icon="essentials_save" size={12} color="white" />
    });
  }

  if (!!onDelete) {
    base.unshift({
      name: t('DELETE'),
      action: 'error',
      variant: 'light',
      icon: <Icon icon="essentials_bin" size={12} color="red" />,
      onClick: () =>
        showDelete({
          title: t(deleteTitle),
          message: t(deleteMessage),
          onConfirm: onDelete
        })
    });
  }

  if (!!backLink) {
    base.unshift({
      name: t('BACK'),
      onClick: () => navigate(backLink),
      action: 'secondary',
      color: 'gray',
      variant: 'light',
      icon: <Icon icon="basics_left" size={12} color={XelaColor.Gray9} />
    });
  }

  return base;
};

export default headerActions;
