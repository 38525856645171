import { Block, Grid, TextInput} from '@codepoint-pt/xela';
import { FC, useContext } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Description, DescriptionText, DescriptionTitle, FinalUrlTitle, FinalUrl } from './styles';
import { AnyObject } from '../../models/Generic';
import { InfoContext } from '../../Context';
import { Category } from '../../models/Category';
import TranslatableFields from '../inputs/TranslatableFields';

const MUNICIPALITY = 'municipality';
const NEWS = 'news';
const POI = 'poi';
const PAGE = 'page';
const EVENT = 'event';
const ITINERARY = 'itinerary';

type Section = typeof MUNICIPALITY | typeof NEWS | typeof POI | typeof PAGE | typeof EVENT | typeof ITINERARY;

const sections = [
  { section: MUNICIPALITY, url: '/municipio' },
  { section: NEWS, url: '/noticias' },
  { section: POI, url: '/pois/{{CATEGORY}}' },
  { section: PAGE, url: '' },
  { section: EVENT, url: '/eventos' },
  { section: ITINERARY, url: '/roteiros' }
];

interface MetaDataTabProps {
  subValidate: (fields: string[]) => boolean;
  values: AnyObject;
  section: Section;
  withAsterisk?: boolean;
}

const getUrl = (section: Section, values: AnyObject, categories?: Category[]) => {
  let sectionUrl = sections.find(elem => elem.section === section)?.url || '';

  if(section === POI && values.category) {
    const categoryUrl = categories?.find(elem => elem._id === values.category)?.slug || '';

    sectionUrl = sectionUrl.replace('{{CATEGORY}}', categoryUrl);
  }

  return values.slug ? `${process.env.REACT_APP_WEBSITE_URL}${sectionUrl}/${values.slug}` : '';
};

const MetaDataTab: FC<MetaDataTabProps> = ({
  subValidate,
  values,
  section,
  withAsterisk = true
}) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const url = getUrl(section, values, info?.categories);

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <Field name="slug">
            {(props) => (
              <TextInput 
                {...props} 
                label={t('URL')}
                placeholder={t('ENTER_URL')}
                withAsterisk={withAsterisk}
              />
            )}
          </Field>
          <Description>
            <DescriptionTitle>{t('NOTE')}:</DescriptionTitle>
            <DescriptionText>{t('URL_NOTE_MESSAGE')}</DescriptionText>
          </Description>
          <Description>
            <DescriptionTitle>{t('EXAMPLE')}:</DescriptionTitle>
            <DescriptionText>{t('URL_EXAMPLE_MESSAGE')}</DescriptionText>
          </Description>
        </Grid.Col>
        {
          url &&
          <Grid.Col xs={12}>
            <Description>
              <FinalUrlTitle>{t('WEBSITE_URL')}:</FinalUrlTitle>
              <FinalUrl href={url} target='_blank'>{url}</FinalUrl>
            </Description>
          </Grid.Col>
        }
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'METADATA_TITLE',
                name: 'metadata.title',
                type: 'text'
              },
              {
                label: 'METADATA_DESCRIPTION',
                name: 'metadata.description',
                type: 'textarea'
              },
              {
                label: 'METADATA_KEYWORDS',
                name: 'metadata.keywords',
                type: 'textarea'
              }
            ]}
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default MetaDataTab;
