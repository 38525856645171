/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseButton, Block, HStack, Typography, XelaColor } from "@codepoint-pt/xela";
import { GetDraftStatus } from "../../models/Draft";
import Label from "../../components/label";

export const renderAlert = (t: (tag: string, props?: any) => string, navigate: (to: string, options?: any) => void, message: string, url: string) => {
  return (
    <Block borderRadius="16px" bg={XelaColor.Yellow11} padding="15px" margin="10px 0">
      <HStack alignItems='center' justifyContent='space-between'>
        <Typography variant='body' style={{ marginRight: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>{t('NOTE')}: </span>
          {t(message)}
        </Typography>
        <BaseButton
          type="button"
          onClick={() => navigate(url)}
        >
          {t('VIEW_REQUEST')}
        </BaseButton>
      </HStack>
    </Block>
  );
};

export const renderStatus = (t: (tag: string, props?: any) => string, value?: string) => {
  const status = GetDraftStatus(value);

  if(!status) return null;

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <HStack>
        <Typography variant='body-bold' style={{ marginRight: '10px' }}>{t('STATUS')}:</Typography>
        <Label 
          text={t(status.name)} 
          fontColor={status.fontColor} 
          bgColor={status.bgColor} 
        />
      </HStack>
    </Block>
  );
};
