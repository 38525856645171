import { XelaColor } from '@codepoint-pt/xela';
import styled from 'styled-components';

export const AlertText = styled.div`
  font-size: 16px;
  font-weight: bold;

  & .red {
    color: ${XelaColor.Red1};
  }
`;

export const RequiredFieldsWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  padding: 10px;
  margin-bottom: 10px;

  & .title {
    color: ${XelaColor.Red1};
    font-weight: bold;
  }

  & .text {
    margin-left: 10px;
  }
`;
