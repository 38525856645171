import { AspectRatios, Grid, ImageInput, SelectInput, TextInput } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../utils/languages/AvailableTranslations';
import { AdminTypesList, MUNICIPALITY_ADMIN, User } from '../../models/User';
import { FC, useContext } from 'react';
import { InfoContext } from '../../Context';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';
import IntlMultipleSelectInput from '../../components/inputs/IntlMultipleSelectInput';

interface Props {
  values: User;
}

const UserForm: FC<Props> = ({ values }) => {
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);

  return (
    <>
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="photo">
            {(props) => (
              <ImageInput
                {...props}
                ratio={AspectRatios['1:1']}
                label={t('PHOTO')}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="name">
            {(props) => <TextInput {...props} label={`${t('NAME')} *`} />}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="email">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('EMAIL')} *`}
                htmlType="email"
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="type">
            {(props) => (
              <IntlSelectInput
                {...props}
                label={`${t('TYPE')} *`}
                data={AdminTypesList}
                translatable
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      {
        values?.type === MUNICIPALITY_ADMIN &&
        <Grid>
          <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
          <Grid.Col md={4} sm={6} xs={12}>
            <Field name="municipalities">
              {(props) => (
                <IntlMultipleSelectInput
                  {...props}
                  label={`${t('MUNICIPALITIES')} *`}
                  data={info.municipalities}
                />
              )}
            </Field>
          </Grid.Col>
        </Grid>
      }
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="language">
            {(props) => (
              <SelectInput
                {...props}
                label={t('LANGUAGE')}
                data={Languages}
                keyValue='code'
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default UserForm;
