import { AnyObject } from './Generic';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

type NewsStatus = typeof ACTIVE | typeof INACTIVE;

export const NewsStatusList = [
  { _id: ACTIVE, name: ACTIVE, bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50' },
  { _id: INACTIVE, name: INACTIVE, bgColor: 'rgba(221, 64, 64, 0.1)', fontColor: '#DD4040' }
];

export interface News {
  readonly _id: string;
  slug: string;
  title: string;
  date: Date;
  summary?: AnyObject;
  description?: AnyObject;
  photo?: AnyObject;
  status: NewsStatus;
  metadata?: {
    title: AnyObject;
    description: AnyObject;
    keywords: AnyObject;
  };
}
