import { Block } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MapInput from '../../../components/inputs/MapInput';

const MapTab: FC = () => {
  const { t } = useTranslation();
  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Field name="coordinates">
        {(props) => (
          <MapInput
            {...props}
            label={t('COORDINATES')}
            latitudeLabel={t('LATITUDE')}
            latitudePlaceholder={t('INPUT_LATITUDE_PLACEHOLDER')}
            longitudeLabel={t('LONGITUDE')}
            longitudePlaceholder={t('INPUT_LONGITUDE_PLACEHOLDER')}
            withAsterisk={false}
          />
        )}
      </Field>
    </Block>
  );
};

export default MapTab;
