import { Category } from './Category';
import { AWAITING_VALIDATION, REJECTED } from './Draft';
import { AnyObject } from './Generic';
import { Municipality } from './Municipality';

const SQUARE = 'SQUARE';
const CIRCLE = 'CIRCLE';
const RECTANGLE = 'RECTANGLE';

type StampTypes = typeof SQUARE | typeof CIRCLE | typeof RECTANGLE;

const LEFT = 'LEFT';
const RIGHT = 'RIGHT';

type StampAlignTypes = typeof LEFT | typeof RIGHT;

const WAVES_1 = 'WAVES_1';
const WAVES_2 = 'WAVES_2';
const RECTANGLE_1 = 'RECTANGLE_1';
const RECTANGLE_2 = 'RECTANGLE_2';

type StampSymbolTypes = typeof WAVES_1 | typeof WAVES_2 | typeof RECTANGLE_1 | typeof RECTANGLE_2;

export const priceIntervals = [
  { _id: 1, name: '€' },
  { _id: 2, name: '€€' },
  { _id: 3, name: '€€€' }
];

export const numberStars = [
  { _id: 1, name: 1 },
  { _id: 2, name: 2 },
  { _id: 3, name: 3 },
  { _id: 4, name: 4 },
  { _id: 5, name: 5 }
];

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

type PoiStatus = typeof ACTIVE | typeof INACTIVE | typeof AWAITING_VALIDATION | typeof REJECTED;

export const PoiStatusList = [
  { _id: ACTIVE, name: ACTIVE, bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50' },
  { _id: INACTIVE, name: INACTIVE, bgColor: 'rgba(221, 64, 64, 0.1)', fontColor: '#DD4040' }
];

export interface Poi {
  readonly _id: string;
  slug?: string;
  name: AnyObject;
  category?: Category;
  sub_category?: Category;
  municipality?: Municipality;
  description?: string;
  images?: AnyObject[];
  schedule?: string;
  contact?: string;
  phone?: string;
  phone2?: string;
  address?: string;
  email?: string;
  website?: string;
  website_reservation?: string;
  coordinates?: {
    latitude: string;
    longitude: string;
  };
  parking?: boolean;
  passport?: boolean;
  filter_price?: number;
  filter_stars?: number;
  languages?: string[];
  delicacies?: AnyObject;
  metadata?: {
    title: AnyObject;
    description: AnyObject;
    keywords: AnyObject;
  };
  stamp: {
    type: StampTypes;
    seal_align: StampAlignTypes;
    symbol: StampSymbolTypes;
  },
  status: PoiStatus;
  draft?: AnyObject;
}
