import { Field, withTypes } from 'react-final-form';
import yup from '@codepoint-pt/yup-validations';
import { BasicForm } from '../../../../styles/BasicStyles';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import TranslatableFields from '../../../../components/inputs/TranslatableFields';
import useSectionValidation from '../../../../hooks/use-validation/use-section-validation';
import {
  Modal,
  XelaColor,
  Icon,
  Grid,
  BaseButton,
  TextInput
} from '@codepoint-pt/xela';
import { DayBlock } from '../../../../models/Itinerary';
import useIntlValidation from '../../../../hooks/use-validation/use-inlt-validation';

interface Params {
  opened: boolean;
  onClose: (changed?: DayBlock) => void;
  initialValues?: DayBlock;
  t: (v: string) => string;
}

const { Form } = withTypes<DayBlock>();

const BlockModal = ({ opened, onClose, initialValues, t }: Params) => {
  const intlObject = useIntlValidation();
  const validatedSchema = useValidationSchema(
    yup.object({
      html: intlObject({ required: true, html: true }),
      km: yup.number().required()
    })
  );

  const onSubmit = async (values: DayBlock) => {
    onClose(values);
  };

  return (
    <Modal
      overlayColor={XelaColor.Gray11}
      padding="xl"
      size="xl"
      centered
      opened={opened}
      onClose={() => onClose()}
      title={t('DAY')}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      {opened && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validatedSchema}
        >
          {({ handleSubmit, errors, submitFailed }) => {
            return (
              <BasicForm onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Col span={12}>
                    <TranslatableFields
                      subValidate={(fields) =>
                        useSectionValidation(errors, fields, submitFailed)
                      }
                      fields={[
                        { label: 'CONTENT', name: 'html', type: 'richtext' }
                      ]}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="km">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('KILOMETERS')}
                          placeholder={t('INPUT_KILOMETERS_PLACEHOLDER')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12} style={{ textAlign: 'right' }}>
                    <BaseButton
                      type="submit"
                      leftIcon={<Icon color="white" icon="essentials_save" />}
                    >
                      {t('SAVE')}
                    </BaseButton>
                  </Grid.Col>
                </Grid>
              </BasicForm>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};

export default BlockModal;
