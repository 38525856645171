import { Country } from './Country';
import { Gender } from './Gender';
import { AnyObject } from './Generic';
import { Interest } from './Interest';
import { Itinerary } from './Itinerary';
import { Poi } from './Poi';

interface Communications {
  marketing?: boolean;
  notifications?: boolean;
  optional_emails?: boolean;
}

export interface Passport {
  user: User;
  poi: Poi;
  createdAt: Date;
}

export const ADMIN = 'ADMIN';
export const MUNICIPALITY_ADMIN = 'MUNICIPALITY_ADMIN';

export const AdminTypesList = [
  { _id: ADMIN, name: ADMIN },
  { _id: MUNICIPALITY_ADMIN, name: MUNICIPALITY_ADMIN }
];

export interface User {
  readonly _id: string;
  name: string;
  email: string;
  type: string;
  accepted?: boolean;
  active?: boolean;
  admin?: boolean;
  birth?: Date;
  gender?: Gender;
  nationality?: string;
  country?: Country;
  city?: string;
  interests?: Interest[];
  municipalities?: string[];
  favorites_pois?: Poi[];
  favorites_itineraries?: Itinerary[];
  communications?: Communications;
  superuser?: boolean;
  createdAt: Date;
  updatedAt: Date;
  photo?: AnyObject;
  password?: string;
  confirm_password?: string;
  language?: string;
  passport?: Passport[];
  passport_completed?: Date;
}
