/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { MultiSelectInput, MultiSelectInputProps } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '../../models/Generic';

const translateToString = (value: AnyObject, t: any, i18n: any) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value;
  }
  if (!value || typeof value !== 'object') return t('TRANSLATION_NOT_FOUND');
  if (value[i18n.resolvedLanguage] && value[i18n.resolvedLanguage] !== '')
    return value[i18n.resolvedLanguage];
  return t('TRANSLATION_NOT_FOUND');
};

const IntlMultipleSelectInput: FC<MultiSelectInputProps> = ({
  keyValue = '_id',
  keyLabel = 'name',
  searchable = true,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const data = props.data.map((data) => ({
    ...data,
    value: data[keyValue],
    label: translateToString(data[keyLabel], t, i18n)
  }));
  return <MultiSelectInput {...props} searchable={searchable} data={data} keyLabel="label" dropdownPosition='flip' />;
};

export default IntlMultipleSelectInput;
