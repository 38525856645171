import { BaseTable, Block, DateInput, Grid, PageFilters, PageHeader, SearchInput, SelectInput, TableColumnsProps, createPaginationRequest, useDidUpdate } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyObject } from '../../models/Generic';
import { Municipality } from '../../models/Municipality';
import { InfoContext, UserContext } from '../../Context';
import { useContext, useEffect, useState } from 'react';
import { ADMIN } from '../../models/User';
import { DraftStatusList, DraftTypesList, EVENT, GetDraftStatus, ITINERARY, POI } from '../../models/Draft';
import { showSuccess } from '../../hooks/show-notification/show-notification';
import dayjs from 'dayjs';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';
import Label from '../../components/label';

const RecordsTable = BaseTable<AnyObject>();

const ListPage = () => {
  const [ready, setReady] = useState<boolean>(false);
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<AnyObject>({});
  const { t } = useTranslation();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { get } = useFetch('/drafts');

  useEffect(() => {
    const init = () => {
      const json = localStorage.getItem('DRAFTS_FILTERS');
      const obj = JSON.parse(json || '{}');
      if(obj) setFilters(obj);
      setReady(true);
    };

    init();

    if(search.includes('success')) {
      showSuccess({
        title: t('SUCCESS'),
        message: t('DRAFT_CREATED')
      });

      navigate('/drafts', { replace: true });
    }
  }, []);

  useDidUpdate(() => {
    const init = async () => {
      setLoading(true);
      const url = createPaginationRequest(
        '/',
        filters,
        undefined,
        ['title|intl']
      );

      const { data } = await get(url);
      setRows(data || []);
      setLoading(false);
    };
    init();
  }, [filters]);

  const handleNewFilters = (filters: AnyObject) => {
    localStorage.setItem('DRAFTS_FILTERS', JSON.stringify(filters));

    setFilters(filters);
  };
  
  const columns: TableColumnsProps<AnyObject>[] = [
    {
      title: t('CHANGE_DATE'),
      dataIndex: 'date',
      render: (value: Date) => dayjs(value).format('DD/MM/YYYY, HH:mm')
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      render: (value: AnyObject) => t(value)
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      render: (value: AnyObject) => <ActiveTranslation value={value || 'N/A'} />
    },
    {
      title: t('MUNICIPALITY'),
      dataIndex: 'municipality',
      render: (value: Municipality) => <ActiveTranslation value={value?.name || 'N/A'} />
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      render: (value: string) => {
        const status = GetDraftStatus(value);

        return (
          <Label 
            text={status && t(status?.name)} 
            fontColor={status?.fontColor} 
            bgColor={status?.bgColor} 
          />
        );
      }
    }
  ];

  const goToDetail = (row: AnyObject) => {
    let type = '';

    if(row.type === EVENT) type = 'event';
    else if(row.type === ITINERARY) type = 'itinerary';
    else if(row.type === POI) type = 'poi';

    if(type) navigate(`/drafts/${type}/${row._id}`);
  };

  if(!ready) return null;

  return (
    <Block>
      <PageHeader
        title={t('DRAFTS')}
        breadcrumbs={[{ title: t('DRAFTS'), href: '/drafts' }]}
      />
      <Block
        bg="white"
        borderRadius="16px"
        padding="15px"
        margin="0 0 30px 0"
      >
        <PageFilters
          initialFilters={filters}
          onChange={handleNewFilters}
        >
          {({ filters, handleChange }) => (
            <Grid gutter={24}>
              <Grid.Col xs={6}>
                <SearchInput
                  input={{
                    value: filters.search,
                    onChange: (v: string | null) => handleChange('search', v, 1000)
                  }}
                  onSearch={(v) => handleChange('search', v)}
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <SelectInput
                  placeholder={t('SELECT_MUNICIPALITY')}
                  input={{
                    value: filters.municipality,
                    onChange: (v: string | null) => handleChange('municipality', v, 1000)
                  }}
                  data={info?.municipalities?.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
                  clearable
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <IntlSelectInput
                  input={{
                    value: filters.type || '',
                    onChange: (v: string | null) => handleChange('type', v, 1000)
                  }}
                  placeholder={t('CHOOSE_TYPE')}
                  clearable
                  data={DraftTypesList}
                  translatable
                />
              </Grid.Col>
              <Grid.Col xs={3}>
                <IntlSelectInput
                  input={{
                    value: filters.status || '',
                    onChange: (v: string | null) => handleChange('status', v, 1000)
                  }}
                  placeholder={t('CHOOSE_STATUS')}
                  clearable
                  data={DraftStatusList}
                  translatable
                />
              </Grid.Col>
              <Grid.Col xs={3}>
              <DateInput 
                placeholder={t('SELECT_DATE')}
                input={{
                  value: filters.request_date,
                  onChange: (v: string | null) => handleChange('request_date', v, 1000)
                }}
                clearable
              />
            </Grid.Col>
            </Grid>
          )}
        </PageFilters>
      </Block>
      <RecordsTable
        columns={columns}
        rows={rows}
        loading={loading}
        onRowClick={(row) => goToDetail(row)}
      />
    </Block>
  );
};

export default ListPage;
