export const EVENT = 'EVENT';
export const ITINERARY = 'ITINERARY';
export const POI = 'POI';

export const DraftTypesList = [
  { _id: EVENT, name: EVENT },
  { _id: ITINERARY, name: ITINERARY },
  { _id: POI, name: POI }
];

export const REJECTED = 'REJECTED';
export const AWAITING_VALIDATION = 'AWAITING_VALIDATION';

export const DraftStatusList = [
  { _id: AWAITING_VALIDATION, name: AWAITING_VALIDATION, bgColor: 'rgba(255, 160, 0, 0.09)', fontColor: '#FFA000' },
  { _id: REJECTED, name: REJECTED, bgColor: 'rgba(217, 99, 99, 0.1)', fontColor: '#D96363' }
];

export const GetDraftStatus = (value?: string) => value ? DraftStatusList.find(elem => elem._id === value) : undefined;
