import { XelaColor } from '@codepoint-pt/xela';
import styled from 'styled-components';

export const DateTimeWrapper = styled.div<{ size?: string, hasError?: boolean, hasLeftIcon?: boolean, hasRightIcon?: boolean, isExternal?: boolean, hasActionButtons?: boolean }>`
  & .react-datetime-picker {
    width: 100%;
    height: 36px;

    & .react-datetime-picker__wrapper {
      border-radius: 16px;
      border: 1px solid ${({ hasError }) => hasError ? '#fa5252' : XelaColor.Gray10}; 
      padding-left: 12px;
      padding-right: 5px;

      & input {
        &:focus-visible {
          outline: 0;
        }

        &::placeholder {
          color: ${({ hasError }) => hasError ? '#fa5252' : 'rgb(117, 117, 117)'};
        }
      }

      & .react-datetime-picker__inputGroup__input:invalid {
        background-color: transparent;
      }

      & .react-datetime-picker__inputGroup__divider {
        color: ${({ hasError }) => hasError ? '#fa5252' : XelaColor.Gray3};
      }

      & .react-datetime-picker__button {
        & svg path[fill] {
          fill: ${({ hasError }) => hasError ? '#fa5252' : XelaColor.Gray7};
        }

        & svg path[stroke] {
          stroke: ${({ hasError }) => hasError ? '#fa5252' : XelaColor.Gray7};
        }
      }
    }
  }
`;

export const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 37, 41);
  word-break: break-word;
  cursor: default;
`;
