import { AspectRatios, Block, Grid, ImageInput, SelectInput, TextInput } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Interest } from '../../../models/Interest';
import { Municipality } from '../../../models/Municipality';
import { EventCategory } from '../../../models/EventCategory';
import { ADMIN, MUNICIPALITY_ADMIN, User } from '../../../models/User';
import TranslatableFields from '../../../components/inputs/TranslatableFields';
import IntlMultipleSelectInput from '../../../components/inputs/IntlMultipleSelectInput';
import DateTimeInput from '../../../components/inputs/DateTimeInput';
import IntlSelectInput from '../../../components/inputs/IntlSelectInput';

interface InformationTabProps {
  municipalities: Municipality[];
  interests: Interest[];
  eventCategories: EventCategory[];
  subValidate: (fields: string[]) => boolean;
  user?: User;
}

const InformationTab: FC<InformationTabProps> = ({
  municipalities,
  interests,
  eventCategories,
  subValidate,
  user
}) => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={6} offset={3}>
          <Field name="photo">
            {(props) => (
              <ImageInput
                {...props}
                ratio={AspectRatios['16:9']}
                label={`${t('PHOTO')} *`}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'TITLE',
                name: 'title',
                type: 'text',
                required: true
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'richtext'
              }
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <Field name="start_datetime">
            {(props) => (
              <DateTimeInput
                {...props}
                label={t('START_DATETIME')}
                withAsterisk
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={6}>
          <Field name="end_datetime">
            {(props) => (
              <DateTimeInput
                {...props}
                label={t('END_DATETIME')}
                withAsterisk
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="category">
            {(props) => (
              <IntlSelectInput
                {...props}
                label={t('CATEGORY')}
                placeholder={t('INPUT_CATEGORY_PLACEHOLDER')}
                data={eventCategories}
                searchable
                withAsterisk
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="municipality">
            {(props) => (
              <SelectInput
                {...props}
                label={t('MUNICIPALITY')}
                placeholder={t('INPUT_MUNICIPALITY_PLACEHOLDER')}
                data={municipalities.filter(elem => user?.type === ADMIN || user?.municipalities?.includes(elem._id))}
                searchable
                clearable
                withAsterisk={user?.type === MUNICIPALITY_ADMIN}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="website">
            {(props) => (
              <TextInput 
                {...props} 
                label={t('WEBSITE')}
                placeholder={t('INPUT_WEBSITE_PLACEHOLDER')}
                description={t('INPUT_WEBSITE_INSTRUCTIONS')}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="address">
            {(props) => (
              <TextInput 
                {...props} 
                label={t('ADDRESS')}
                placeholder={t('INPUT_ADDRESS_PLACEHOLDER')}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="interests">
            {(props) => (
              <IntlMultipleSelectInput
                {...props}
                label={t('INTERESTS')}
                placeholder={t('INPUT_INTERESTS_PLACEHOLDER')}
                data={interests}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
