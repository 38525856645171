import { AspectRatios, Block, CheckboxInput, DateInput, Grid, ImageInput, SelectInput, TextInput } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../../utils/languages/AvailableTranslations';
import { User } from '../../../models/User';
import { Gender } from '../../../models/Gender';
import { Country } from '../../../models/Country';
import { Interest } from '../../../models/Interest';
import IntlSelectInput from '../../../components/inputs/IntlSelectInput';
import IntlMultipleSelectInput from '../../../components/inputs/IntlMultipleSelectInput';

interface InformationTabProps {
  initialValues?: User;
  genders: Gender[];
  countries: Country[];
  interests: Interest[];
}

const InformationTab: FC<InformationTabProps> = ({
  initialValues,
  genders,
  countries,
  interests
}) => {
  const { t } = useTranslation();

  return (
    <Block
      borderRadius="16px"
      bg="white"
      padding="50px 15px"
      margin="10px 0"
    >
      <Block margin="0 auto" style={{ maxWidth: '700px' }}>
        <Grid>
          <Grid.Col xs={6} offset={3}>
            <Field name="photo">
              {(props) => (
                <ImageInput
                  {...props}
                  ratio={AspectRatios['1:1']}
                  label={t('PHOTO')}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <DateInput  
              label={t('DATE_OF_REGISTRATION')}
              meta={{}}
              input={{
                value: initialValues?.createdAt,
                onChange: () => null
              }}
              disabled
            />
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="email">
              {(props) => (
                <TextInput
                  {...props}
                  label={t('EMAIL')}
                  htmlType="email"
                  disabled
                  withAsterisk
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="name">
              {(props) => <TextInput {...props} label={t('NAME')} withAsterisk/>}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="birth">
              {(props) => (
                <DateInput {...props} label={t('DATE_OF_BIRTH')} />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="gender">
              {(props) => (
                <IntlSelectInput
                  {...props}
                  label={t('GENDER')}
                  data={genders}
                  clearable
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="nationality">
              {(props) => <TextInput {...props} label={t('NATIONALITY')} />}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="city">
              {(props) => <TextInput {...props} label={t('CITY')} />}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="country">
              {(props) => (
                <IntlSelectInput
                  {...props}
                  label={t('COUNTRY')}
                  data={countries}
                  clearable
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="language">
              {(props) => (
                <SelectInput
                  {...props}
                  label={t('LANGUAGE')}
                  data={Languages}
                  keyValue='code'
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Field name="interests">
              {(props) => (
                <IntlMultipleSelectInput
                  {...props}
                  label={t('INTERESTS')}
                  data={interests}
                />
              )}
            </Field>
          </Grid.Col>
          <Grid.Col xs={12}>
            <div>
              <Field name="communications.marketing">
                {(props) => (
                  <CheckboxInput
                    {...props}
                    label={t('COMMUNICATION_PREFERENCES')}
                    placeholder={t('MARKETING_COMMUNICATION')}
                  />
                )}
              </Field>
            </div>
            <div style={{ marginTop: '5px' }}>
              <Field name="communications.notifications">
                {(props) => (
                  <CheckboxInput
                    {...props}
                    placeholder={t('APP_NOTIFICATIONS')}
                  />
                )}
              </Field>
            </div>
            <div style={{ marginTop: '5px' }}>
              <Field name="communications.optional_emails">
                {(props) => (
                  <CheckboxInput
                    {...props}
                    placeholder={t('OPTIONAL_EMAILS')}
                  />
                )}
              </Field>
            </div>
          </Grid.Col>
        </Grid>
      </Block>
    </Block>
  );
};

export default InformationTab;
