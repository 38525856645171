import { Alert, Grid, XelaColor } from '@codepoint-pt/xela';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface StatusProps {
  showAlert: boolean;
}

const AlertComponent: FC<StatusProps> = ({ showAlert }) => {
  const { t } = useTranslation();
  
  return (
    <>
      {
        showAlert &&
        <Grid>
          <Grid.Col xs={12}>
            <Alert 
              title={t('SUCCESS')}
              content={t('NOTIFICATION_CREATED')}
              bg={XelaColor.Indigo12}
              primaryColor={XelaColor.Green1}
            />
          </Grid.Col>
        </Grid>
      }
    </>
  );
};

export default AlertComponent;
