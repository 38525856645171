import { LoadingOverlay, PageHeader, toFormData } from '@codepoint-pt/xela';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { DynamicPage } from '../../models/DynamicPage';
import { BasicForm } from '../../styles/BasicStyles';
import { useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ValidationErrors } from 'final-form';
import { AnyObject } from '../../models/Generic';
import yup, { isSlug } from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import BaseTabs from '../../components/tabs/BaseTabs';
import MetaDataTab from '../../components/metadata';
import InformationTab from './tabs/InformationTab';

const { Form } = withTypes<DynamicPage>();

const ManageDynamicPagesPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();
  const { get, post, put } = useFetch('/dynamic-pages');

  useEffect(() => {
    const init = async () => {
      if(id) {
        const { data, success } = await get(`/${id}`);
        if(success) setInitialValues(data);
      }

      setLoading(false);
    };

    init();
  }, [id]);

  const onSubmit = async (values: DynamicPage) => {
    let success = false;
    const payload = toFormData(values);

    if(id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } 
    else {
      const result = await post(payload);
      success = result.success;
    }

    if(success) navigate('/dynamic-pages');
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean, values: AnyObject) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: (
          <InformationTab
            subValidate={(fields) =>
              useSectionValidation(errors, fields, submitFailed)
            }
          />
        ),
        error: useSectionValidation(
          errors,
          ['name', 'content'],
          submitFailed
        )
      },
      {
        value: 'metadata',
        label: t('METADATA'),
        children: <MetaDataTab subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)} values={values} section='page' />,
        error: useSectionValidation(
          errors,
          ['slug'],
          submitFailed
        )
      }
    ];

    return tabs;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          title: intlObject({ required: true }),
          content: intlObject({ required: true, html: true }),
          slug: isSlug.required()
        })
      )}
    >
      {({ values, handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_DYNAMIC_PAGE' : 'ADD_DYNAMIC_PAGE')}
            onBack={() => navigate('/dynamic-pages')}
            breadcrumbs={[
              { title: t('DYNAMIC_PAGES'), href: '/dynamic-pages' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/dynamic-pages'
            })}
          />
          <BaseTabs
            baseTab="information"
            tabs={defineTabs(errors, submitFailed, values)}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageDynamicPagesPage;
