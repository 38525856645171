import { HeaderBaseAction, Icon, LoadingOverlay, PageHeader, XelaColor, toFormData } from '@codepoint-pt/xela';
import { useContext, useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../styles/BasicStyles';
import { Itinerary } from '../../models/Itinerary';
import { ValidationErrors } from 'final-form';
import { Interest } from '../../models/Interest';
import { AnyObject } from '../../models/Generic';
import { InfoContext, UserContext } from '../../Context';
import { ADMIN, MUNICIPALITY_ADMIN } from '../../models/User';
import { EVENT, ITINERARY, POI, REJECTED } from '../../models/Draft';
import { renderAlert, renderStatus } from '../../utils/draft';
import { showConfirm, showSuccess } from '../../hooks/show-notification/show-notification';
import yup, { isMongoId, isSlug } from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import ContactsTab from './tabs/ContactsTab';
import InformationTab from './tabs/InformationTab';
import PoisTab from './tabs/PoisTab';
import DaysTab from './tabs/DaysTab';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import TipsTab from './tabs/TipsTab';
import MetaDataTab from '../../components/metadata';
import GetFile from '../../utils/files/GetFile';
import RejectModal from '../drafts/components/RejectModal';

const { Form } = withTypes<Itinerary>();

const ManageItineraryPage = () => {
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<Itinerary>();
  const [rejectModal, setRejectModal] = useState<{ open: boolean; type?: typeof EVENT | typeof ITINERARY | typeof POI; title?: string; id?: string; }>({ open: false });
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();
  const { user } = useContext(UserContext);
  const { info } = useContext(InfoContext);
  const itineraryHook = useFetch('/itineraries');
  const draftHook = useFetch('/drafts/itinerary');
  const isDraft = location.pathname.includes('/drafts/itinerary/');

  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = async () => {
    if(id) {
      const { data, success } = isDraft ? await draftHook.get(`/${id}`) : await itineraryHook.get(`/${id}`);
      
      if(success) {
        if(user?.type === MUNICIPALITY_ADMIN && !user?.municipalities?.includes(data?.municipality?._id)) return navigate(isDraft ? '/drafts' : '/itineraries');

        setInitialValues({ 
          ...data, 
          interests: data.interests?.map((elem: Interest) => elem._id),
          municipality: data?.municipality?._id
        });
      }
    }

    setLoading(false);
  };

  const onSubmit = async (values: Itinerary) => {
    let success = false;
    
    // origin_itinerary is only needed when a Municipality Admin edits a production itinerary, we need to save that itinerary ID to link the itinerary and draft
    const payload = isDraft ? toFormData(values) : toFormData({ ...values, origin_itinerary: id });

    if(id) {
      let result = null;

      // If the user is a Municipality Admin, when trying to edit a production itinerary, we need to create a itinerary Draft instead
      if(user?.type === MUNICIPALITY_ADMIN && !isDraft) {
        result = await draftHook.post(payload);
      }
      else {
        result = isDraft ? await draftHook.put(`/${values._id}`, payload) : await itineraryHook.put(`/${values._id}`, payload);
      }

      success = result.success;
    } 
    else {
      const result = user?.type === MUNICIPALITY_ADMIN ? await draftHook.post(payload) : await itineraryHook.post(payload);
      success = result.success;
    }

    if(success) {
      /* 
      * Logic explained:
      * IF USER IS MUNICIPALITY ADMIN
      *   - New itinerary draft OR edit production itinerary » /drafts?success
      *   - Edit draft itinerary » /drafts
      * 
      * IF USER IS ADMIN
      *   - New or edit draft itinerary » /drafts
      *   - New or edit production itinerary » /itineraries
      */

      if(user?.type === MUNICIPALITY_ADMIN) {
        if(id && isDraft) navigate('/drafts');
        else navigate('/drafts?success');
      }
      else {
        if(isDraft) navigate('/drafts');
        else navigate('/itineraries');
      }
    }
  };

  const onDelete = async () => {
    if(id) {
      setLoading(true);

      const { success } = isDraft ? await draftHook.del(`/${id}`) : await itineraryHook.del(`/${id}`);
      if(success) navigate(isDraft ? '/drafts' : '/itineraries');
      else setLoading(false);
    }
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean, values: AnyObject) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: (
          <InformationTab
            subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)}
            user={user}
            municipalities={info?.municipalities}
            initialValues={initialValues}
          />
        ),
        error: useSectionValidation(
          errors,
          ['name'],
          submitFailed
        )
      },
      {
        value: 'days',
        label: t('DAYS'),
        children: <DaysTab />,
        error: useSectionValidation(errors, ['days'], submitFailed)
      },
      {
        value: 'pois',
        label: t('POIS'),
        children: <PoisTab />,
        error: useSectionValidation(errors, ['pois'], submitFailed)
      },
      {
        value: 'tips',
        label: t('TIPS'),
        children: (
          <TipsTab
            subValidate={(fields) =>
              useSectionValidation(errors, fields, submitFailed)
            }
          />
        ),
        error: useSectionValidation(
          errors,
          ['tips', 'recommendations'],
          submitFailed
        )
      },
      {
        value: 'contacts',
        label: t('CONTACTS'),
        children: <ContactsTab />,
        error: useSectionValidation(errors, ['contacts'], submitFailed)
      },
      {
        value: 'metadata',
        label: t('METADATA'),
        children: <MetaDataTab subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)} values={values} section='itinerary' />,
        error: useSectionValidation(
          errors,
          ['slug'],
          submitFailed
        )
      }
    ];

    return tabs;
  };

  const exportPdf = () => {
    showConfirm({
      title: t('CONFIRM'),
      message: t('EXPORT_PDF_TITLE'),
      onConfirm: () => {
        setExportLoading(true);
        GetFile(`${process.env.REACT_APP_API_URL}/itineraries/export/${language}/${id}`, id, setExportLoading);
      }
    });
  };
  
  const renderTitle = () => {
    if(id) {
      if(isDraft) return t('EDIT_DRAFT_ITINERARY');
      else return t('EDIT_ITINERARY');
    }
    else {
      if(isDraft) return t('ADD_DRAFT_ITINERARY');
      else return t('ADD_ITINERARY');
    }
  };

  const validateItinerary = () => {
    showConfirm({
      title: t('CONFIRM'),
      message: t('VALIDATE_ITINERARY'),
      onConfirm: async () => {
        setLoadingValidate(true);

        const { success, data } = await draftHook.put(`/${id}/validate`);

        if(success && data?._id) {
          showSuccess({
            title: t('SUCCESS'),
            message: t('ITINERARY_VALIDATED_MESSAGE')
          });

          navigate(`/itineraries/${data._id}`);
        }

        setLoadingValidate(false);
      }
    });
  };

  const handleCloseRejectModal = (changed: boolean) => {
    if(changed) {
      setLoading(true);
      getInfo();
    }
    setRejectModal({ open: false });
  };

  const renderValidateActions = () => {
    const extra: HeaderBaseAction[] = [];

    if(user?.type === ADMIN && id && !isDraft) {
      extra.push({
        name: t('EXPORT'),
        type: 'button',
        onClick: exportPdf,
        icon: <Icon icon="arrows_download" size={14} color={XelaColor.Green1} />,
        action: 'secondary',
        color: 'green',
        variant: 'light',
        loading: exportLoading,
        disabled: exportLoading
      });
    }

    if(user?.type === ADMIN && isDraft) {
      extra.push({
        name: t('VALIDATE'),
        onClick: validateItinerary,
        action: 'secondary',
        color: 'green',
        variant: 'light',
        icon: <Icon icon="basics_tickCircle" size={12} color={XelaColor.Green1} />,
        loading: loadingValidate,
        disabled: loadingValidate
      });

      if(initialValues?.status !== REJECTED) {
        extra.push({
          name: t('REJECT'),
          onClick: () => setRejectModal({ open: true, type: ITINERARY, title: initialValues?.name[language], id: initialValues?._id }),
          action: 'secondary',
          color: 'orange',
          variant: 'light',
          icon: <Icon icon="basics_xCircle" size={12} color={XelaColor.Orange4} />,
          disabled: loadingValidate
        });
      }
    }

    return extra;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={useValidationSchema(
          yup.object({
            name: intlObject({ required: true }),
            slug: isSlug.required(),
            municipality: user?.type === MUNICIPALITY_ADMIN ? isMongoId.required() : isMongoId.nullable()
          })
        )}
      >
        {({ values, handleSubmit, submitting, pristine, errors, submitFailed }) => (
          <BasicForm onSubmit={handleSubmit}>
            <LoadingOverlay visible={loading} />
            <FormPrompt
              when={!pristine && !submitting && !loading}
              message={t('PROMPT_MESSAGE')}
            />
            <PageHeader
              navigate={navigate}
              title={renderTitle()}
              onBack={() => navigate(isDraft ? '/drafts' : '/itineraries')}
              breadcrumbs={[
                { title: t('ITINERARIES'), href: '/itineraries' },
                { title: t(id ? 'EDIT' : 'ADD') }
              ]}
              actions={headerActions({
                submitting,
                pristine,
                backLink: isDraft ? '/drafts' : '/itineraries',
                onDelete: user?.type === ADMIN && id ? onDelete : undefined,
                extra: renderValidateActions(),
                save: !isDraft && initialValues?.draft?._id ? false : true
              })}
            />
            { user?.type === ADMIN && !isDraft && initialValues?.draft?._id && renderAlert(t, navigate, 'ITINERARY_HAS_DRAFT_ADMIN', `/drafts/itinerary/${initialValues?.draft?._id}`) }
            { user?.type === MUNICIPALITY_ADMIN && !isDraft && initialValues?.draft?._id && renderAlert(t, navigate, 'ITINERARY_HAS_DRAFT', `/drafts/itinerary/${initialValues?.draft?._id}`) }
            { isDraft && renderStatus(t, initialValues?.status) }
            <BaseTabs
              baseTab="information"
              tabs={defineTabs(errors, submitFailed, values)}
            />
          </BasicForm>
        )}
      </Form>
      <RejectModal
        opened={rejectModal.open}
        type={rejectModal.type}
        title={rejectModal.title}
        id={rejectModal.id}
        onClose={handleCloseRejectModal}
      />
    </>
  );
};

export default ManageItineraryPage;
