import { AnyObject, DefaultListPage, BaseTable, Grid, Icon, SearchInput, DateInput, SelectInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { News, NewsStatusList } from '../../models/News';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import dayjs from 'dayjs';
import LabelDropdown from '../../components/labelDropdown';

const NewsListPage = DefaultListPage<News>();
const NewsTable = BaseTable<News>();

const NewsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { get, put } = useFetch('/news');

  return (
    <NewsListPage
      translate={t}
      request={{
        get,
        searchFields: ['title|intl', 'summary|intl', 'description|intl']
      }}
      storage="NEWS_FILTERS"
      header={{
        navigate,
        title: t('BLOG'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/blog/new')
          }
        ],
        breadcrumbs: [{ title: t('BLOG'), href: '/blog' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={6}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <SelectInput 
              placeholder={t('SELECT_STATUS')}
              input={{
                value: filters.status,
                onChange: (v: string | null) => handleChange('status', v, 1000)
              }}
              data={NewsStatusList}
              translate={t}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <DateInput 
              placeholder={t('SELECT_DATE')}
              input={{
                value: filters.date,
                onChange: (v: string | null) => handleChange('date', v, 1000)
              }}
              clearable
            />
          </Grid.Col>
        </Grid>
      )}
    >
      {({ rows, loading, pagination, handleNewSort, refreshPage }) => {
        const onConfirmStatusToogle = async (id: string, status: string) => {
          const { success } = await put(`/${id}/change-status`, { status });
          if(success) refreshPage();
        };
      
        const columns = [
          {
            title: t('TITLE'),
            dataIndex: 'title',
            sortable: true,
            render: (value: AnyObject) => <ActiveTranslation value={value} />
          },
          {
            title: t('DATE'),
            dataIndex: 'date',
            sortable: true,
            render: (value: string) => value && dayjs(value).format('DD/MM/YYYY')
          },
          {
            title: t('STATUS'),
            dataIndex: 'status',
            render: (value: string, news: News) => (
              <LabelDropdown
                status={value}
                list={NewsStatusList}
                id={news._id}
                onConfirmStatusToogle={onConfirmStatusToogle}
              />
            )
          }
        ];

        return (
          <NewsTable
            columns={columns}
            rows={rows}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
            onRowClick={(row) => navigate(`/blog/${row._id}`)}
          />
        );
      }}
    </NewsListPage>
  );
};

export default NewsPage;
