import { FC, ReactNode } from 'react';
import { LabelWrapper, LabelContent } from './styles';
import { Icon, IconTypes, Typography, XelaColor } from '@codepoint-pt/xela';

export type LabelSize = 'xs' | 'md' | undefined;

export interface LabelProps {
  text: string | ReactNode;
  bgColor?: string;
  fontColor?: string;
  size?: LabelSize;
  bRadius?: string;
  fSize?: number;
  lHeight?: number;
  icon?: IconTypes;
  iconColor?: string;
}

export const Label: FC<LabelProps> = ({ 
  text, 
  bgColor = XelaColor.Gray11, 
  fontColor = XelaColor.Gray1, 
  size = 'md', 
  bRadius = '6.25rem', 
  icon,
  iconColor
}) => {
  return (
    <LabelWrapper>
      <LabelContent bRadius={bRadius} bgColor={bgColor} size={size}>
        {
          !!icon &&
          <Icon icon={icon} color={iconColor} />
        }
        <Typography 
          variant='body-small'
          style={{ color: fontColor }}
        >
          {text}
        </Typography>
      </LabelContent>
    </LabelWrapper>
  );
};

export default Label;
