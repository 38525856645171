import { Badge, BaseTable, DefaultListPage, Grid, Icon, SearchInput, SelectInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '../../models/Generic';
import { InfoContext } from '../../Context';
import { useContext } from 'react';
import { EVENT, ITINERARY, LINK, LinkTypesList, Notification, POI, StatusList } from '../../models/Notification';
import dayjs from 'dayjs';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';

const NotificationsListPage = DefaultListPage<Notification>();
const NotificationsTable = BaseTable<Notification>();

const NotificationsPage = () => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { get } = useFetch('/notifications');
  const { info } = useContext(InfoContext);

  const renderLinkType = (data: Notification) => {
    let text = t(LinkTypesList.find(elem => elem._id === data.link_type)?.name || '');

    if(data.link_type === POI) text = `${text} (${data.poi?.name[language]})`;
    else if(data.link_type === EVENT) text = `${text} (${data.event?.title[language]})`;
    else if(data.link_type === ITINERARY) text = `${text} (${data.itinerary?.name[language]})`;
    else if(data.link_type === LINK) text = `${text} (${data.link})`;

    return text;
  };

  const renderState = (data: Notification) => {
    return <Badge label={t(data.state?.status)} textColor='#ffffff' bg={StatusList.find(elem => elem._id === data.state?.status)?.color} />;
  };

  return (
    <NotificationsListPage
      translate={t}
      request={{
        get,
        searchFields: ['text|intl']
      }}
      storage="NOTIFICATIONS_FILTERS"
      header={{
        navigate,
        title: t('NOTIFICATIONS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/notifications/new')
          }
        ],
        breadcrumbs: [{ title: t('NOTIFICATIONS'), href: '/notifications' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={6}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <SelectInput 
              placeholder={t('SELECT_LINK_TYPE')}
              input={{
                value: filters.link_type,
                onChange: (v: string | null) => handleChange('link_type', v, 1000)
              }}
              data={LinkTypesList}
              translate={t}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_COUNTRY')}
              input={{
                value: filters.country,
                onChange: (v: string | null) => handleChange('country', v, 1000)
              }}
              data={info.countries}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_INTEREST')}
              input={{
                value: filters.interest,
                onChange: (v: string | null) => handleChange('interest', v, 1000)
              }}
              data={info.interests}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <SelectInput
              placeholder={t('SELECT_STATUS')}
              input={{
                value: filters['state.status'],
                onChange: (v: string | null) => handleChange('state.status', v, 1000)
              }}
              data={StatusList}
              translate={t}
              clearable
            />
          </Grid.Col>
        </Grid>
      )}
    >
      {({ rows, loading, pagination, handleNewSort }) => {
        const columns = [
          {
            title: t('TEXT'),
            dataIndex: 'text',
            sortable: true,
            render: (value: AnyObject) => value[language]?.length > 20 ? `${value[language]?.substring(0, 20)}...` : value[language]
          },
          {
            title: t('COUNTRY'),
            dataIndex: 'country',
            sortable: true,
            render: (value: AnyObject) => <ActiveTranslation value={value?.name || 'N/A'} />
          },
          {
            title: t('INTEREST'),
            dataIndex: 'interest',
            sortable: true,
            render: (value: AnyObject) => <ActiveTranslation value={value?.name || 'N/A'} />
          },
          {
            title: t('LINK_TYPE'),
            dataIndex: 'link_type',
            sortable: true,
            render: (_: string, data: Notification) => renderLinkType(data)
          },
          {
            title: t('STATUS'),
            dataIndex: 'state',
            render: (_: string, data: Notification) => renderState(data)
          },
          {
            title: t('SEND_DATE'),
            dataIndex: 'state',
            render: (_: string, data: Notification) => data.state?.start_date ? `${dayjs(data.state.start_date).format('DD/MM/YYYY, HH:mm')}h` : 'N/A'
          }
        ];

        return (
          <NotificationsTable
            columns={columns}
            rows={rows}
            loading={loading}
            defaultSort={pagination.sort}
            sortCallback={handleNewSort}
            onRowClick={(row) => navigate(`/notifications/${row._id}`)}
          />
        );
      }}
    </NotificationsListPage>
  );
};

export default NotificationsPage;
