import { Block, LoadingOverlay, PageHeader, toFormData } from '@codepoint-pt/xela';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../styles/BasicStyles';
import { ValidationErrors } from 'final-form';
import { Municipality } from '../../models/Municipality';
import { AnyObject } from '../../models/Generic';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import InformationTab from './tabs/InformationTab';
import MultimediaTab from './tabs/MultimediaTab';
import yup, { isSlug, isUrl } from '@codepoint-pt/yup-validations';
import MetaDataTab from '../../components/metadata';
import ContactsTab from './tabs/ContactsTab';
import MapInput from '../../components/inputs/MapInput';

const { Form } = withTypes<Municipality>();

const ManageMunicipalityPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { get, put } = useFetch('/municipalities');

  useEffect(() => {
    const init = async () => {
      if(id) {
        const { data, success } = await get(`/${id}`);
        if(success) setInitialValues(data);
      }

      setLoading(false);
    };

    init();
  }, [id]);

  const onSubmit = async (values: Municipality) => {
    const payload = toFormData(values);

    const { success } = await put(`/${values._id}`, payload);
    if(success) navigate('/municipalities');
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean, values: AnyObject) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: <InformationTab subValidate={(fields) => useSectionValidation(errors, fields, submitFailed) } />,
        error: useSectionValidation(
          errors,
          ['name'],
          submitFailed
        )
      },
      {
        value: 'multimedia',
        label: t('MULTIMEDIA'),
        children: <MultimediaTab subValidate={(fields) => useSectionValidation(errors, fields, submitFailed) } />
      },
      {
        value: 'coordinates',
        label: t('COORDINATES'),
        error: useSectionValidation(errors, ['coordinates'], submitFailed),
        children: (
          <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
            <Field name="coordinates">
              {(props) => (
                <MapInput
                  {...props}
                  label={t('COORDINATES')}
                  latitudeLabel={t('LATITUDE')}
                  latitudePlaceholder={t('INPUT_LATITUDE_PLACEHOLDER')}
                  longitudeLabel={t('LONGITUDE')}
                  longitudePlaceholder={t('INPUT_LONGITUDE_PLACEHOLDER')}
                />
              )}
            </Field>
          </Block>
        )
      },
      {
        value: 'contacts',
        label: t('CONTACTS'),
        children: <ContactsTab />,
        error: useSectionValidation(
          errors,
          ['contact', 'address', 'phone', 'website'],
          submitFailed
        )
      },
      {
        value: 'metadata',
        label: t('METADATA'),
        children: <MetaDataTab subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)} values={values} section='municipality' />,
        error: useSectionValidation(
          errors,
          ['slug'],
          submitFailed
        )
      }
    ];

    return tabs;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required(),
          slug: isSlug.required(),
          website: isUrl
        })
      )}
    >
      {({ values, handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t('EDIT_MUNICIPALITY')}
            onBack={() => navigate('/municipalities')}
            breadcrumbs={[
              { title: t('MUNICIPALITIES'), href: '/municipalities' },
              { title: t('EDIT') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/municipalities'
            })}
          />
          <BaseTabs
            baseTab="information"
            tabs={defineTabs(errors, submitFailed, values)}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageMunicipalityPage;
