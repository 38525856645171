import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Icon } from '@codepoint-pt/xela';
import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { DateTimeWrapper, InputLabel } from './Styles';
import { GenericInput } from '../../models/Generic';
import DateTimePicker from 'react-datetime-picker';

export interface DateTimeInputProps extends Omit<FieldRenderProps<Date>, 'input'> {
  afterChange?: (value: Date) => void;
  input: GenericInput<Date>;
  withAsterisk?: boolean;
}

export const DateTimeInput: FC<DateTimeInputProps> = ({
  input,
  meta = {},
  afterChange,
  clearable = false,
  label,
  withAsterisk = false,
  ...other
}) => {
  const handleChange = (value: Date | null): void => {
    input.onChange(value);
    
    if(value && afterChange && typeof afterChange === 'function') {
      afterChange(value);
    }
  };

  const hasError = meta?.invalid && meta?.submitFailed;
  const value = input.value ? new Date(input.value) : null;

  return (
    <DateTimeWrapper hasError={hasError}>
      {
        label && 
        <InputLabel>
          {label}
          {withAsterisk && <span style={{ paddingLeft: '4px', color: '#fa5252' }}>*</span>}
        </InputLabel>
      }
      <DateTimePicker
        {...other}
        value={value}
        onChange={handleChange}
        disableClock={true}
        format="dd/MM/y HH:mm"
        clearIcon={clearable && value ? <Icon icon='basics_x' /> : null}
        calendarIcon={<Icon icon='tasks_calendar' />}
      />
    </DateTimeWrapper>
  );
};

export default DateTimeInput;
