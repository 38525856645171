import { AspectRatios, Block, DateInput, Grid, ImageInput } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TranslatableFields from '../../../components/inputs/TranslatableFields';

interface InformationTabProps {
  subValidate: (fields: string[]) => boolean;
}

const InformationTab: FC<InformationTabProps> = ({
  subValidate
}) => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={6} offset={3}>
          <Field name="photo">
            {(props) => (
              <ImageInput
                {...props}
                label={t('PHOTO')}
                ratio={AspectRatios['16:9']}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="date">
            {props => (
              <DateInput
                {...props}
                label={t('DATE')}
                placeholder={t('SELECT_A_DATE')}
                withAsterisk
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'TITLE',
                name: 'title',
                type: 'text',
                required: true
              },
              {
                label: 'SUMMARY',
                name: 'summary',
                type: 'textarea'
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'richtext'
              }
            ]}
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
