import { Block, Divider, Grid, SelectInput, TextInput } from '@codepoint-pt/xela';
import { FC, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Poi } from '../../../models/Poi';
import { Event } from '../../../models/Event';
import { Itinerary } from '../../../models/Itinerary';
import { Interest } from '../../../models/Interest';
import { EVENT, ITINERARY, LINK, LinkTypesList, Notification, POI } from '../../../models/Notification';
import { Country } from '../../../models/Country';
import { TotalUsers } from './styles';
import TranslatableFields from '../../../components/inputs/TranslatableFields';
import IntlSelectInput from '../../../components/inputs/IntlSelectInput';


interface FormFieldsProps {
  pois: Poi[];
  events: Event[];
  itineraries: Itinerary[];
  countries: Country[];
  interests: Interest[];
  subValidate: (fields: string[]) => boolean;
  disableFields: boolean;
  setCountry: (value: string) => void;
  setInterest: (value: string) => void;
  currentUsers: number;
  initialValues?: Notification;
}

const FormFields: FC<FormFieldsProps> = ({
  pois,
  events,
  itineraries,
  countries,
  interests,
  subValidate,
  disableFields,
  setCountry,
  setInterest,
  currentUsers,
  initialValues
}) => {
  const [linkType, setLinkType] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setLinkType(initialValues?.link_type || '');
  }, [initialValues?.link_type]);

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col sm={6} xs={12}>
          <Field name="country">
            {(props) => (
              <IntlSelectInput
                {...props}
                label={t('COUNTRY')}
                placeholder={t('SELECT_COUNTRY')}
                data={countries}
                clearable
                disabled={disableFields}
                afterChange={(value: string | null) => setCountry(value || '')}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <Field name="interest">
            {(props) => (
              <IntlSelectInput
                {...props}
                label={t('INTEREST')}
                placeholder={t('SELECT_INTEREST')}
                data={interests}
                clearable
                disabled={disableFields}
                afterChange={(value: string | null) => setInterest(value || '')}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid style={{ marginTop: '10px' }}>
        <Grid.Col sm={6} xs={12}>
          <TotalUsers>{t('TOTAL_SELECTED_USERS')}:&nbsp;<span>{currentUsers || 0}</span></TotalUsers>
        </Grid.Col>
      </Grid>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <Grid>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'TEXT',
                name: 'text',
                type: 'textarea',
                required: true,
                disabled: disableFields
              }
            ]}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col sm={6} xs={12}>
          <Field name="link_type">
            {(props) => (
              <SelectInput
                {...props}
                label={t('LINK_TYPE')}
                placeholder={t('SELECT_LINK_TYPE')}
                data={LinkTypesList}
                translate={t}
                clearable
                afterChange={(value) => setLinkType(value || '')}
                disabled={disableFields}
              />
            )}
          </Field>
        </Grid.Col>
        {
          !!linkType &&
          <Grid.Col sm={6} xs={12}>
            {
              linkType === POI ?
              <Field name="poi">
                {(props) => (
                  <IntlSelectInput
                    {...props}
                    label={t('POI')}
                    placeholder={t('SELECT_POI')}
                    data={pois}
                    clearable
                    withAsterisk
                    disabled={disableFields}
                  />
                )}
              </Field>
              :
              linkType === EVENT ?
              <Field name="event">
                {(props) => (
                  <IntlSelectInput
                    {...props}
                    label={t('EVENT')}
                    placeholder={t('SELECT_EVENT')}
                    data={events}
                    keyLabel='title'
                    clearable
                    withAsterisk
                    disabled={disableFields}
                  />
                )}
              </Field>
              :
              linkType === ITINERARY ?
              <Field name="itinerary">
                {(props) => (
                  <IntlSelectInput
                    {...props}
                    label={t('ITINERARY')}
                    placeholder={t('SELECT_ITINERARY')}
                    data={itineraries}
                    clearable
                    withAsterisk
                    disabled={disableFields}
                  />
                )}
              </Field>
              :
              linkType === LINK ?
              <Field name="link">
                {(props) => (
                  <TextInput 
                    {...props}
                    label={t('EXTERNAL_LINK')}
                    placeholder={t('INPUT_EXTERNAL_LINK_PLACEHOLDER')}
                    withAsterisk
                    disabled={disableFields}
                  />
                )}
              </Field>
              :
              null
            }
          </Grid.Col>
        }
      </Grid>
    </Block>
  );
};

export default FormFields;
