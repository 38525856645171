import { Typography, VStack, XelaColor, Block, Link } from '@codepoint-pt/xela';
import LanguageSelector from '../../components/languages/LanguageSelector';
import Codepoint from '../../assets/icons/codepoint.svg';
import Logo from '../../assets/icons/logo.png';

interface AuthLayoutProps {
  message?: React.ReactNode;
  subMessage?: React.ReactNode;
  children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  message,
  subMessage,
  children
}) => (
  <Block
    bg={XelaColor.Gray12}
    style={{ minHeight: '100vh', overflowY: 'auto' }}
  >
    <VStack
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: 'calc(100vh - 80px)',
        padding: '40px 0',
        position: 'relative'
      }}
    >
      <img style={{ maxWidth: '380px', maxHeight: '100px', marginBottom: '50px' }} src={Logo} />

      {message && (
        <Typography variant="headline" align="center" style={{ width: '100%' }}>
          {message}
        </Typography>
      )}
      {subMessage && (
        <Typography variant="caption" align="center" style={{ width: '100%' }}>
          {subMessage}
        </Typography>
      )}

      <VStack
        width="380px"
        height="auto"
        bg={XelaColor.White}
        borderRadius="32px"
        style={{ padding: '40px', margin: '24px' }}
        spacing="20px"
      >
        {children}
      </VStack>

      <div style={{ position: 'absolute', top: '15px', right: '15px' }}>
        <LanguageSelector />
      </div>

      <div style={{ position: 'absolute', bottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            variant="body-bold"
            color={XelaColor.Gray8}
          >
            Created by
          </Typography>
          <Link target="_blank" href="https://codepoint.pt" underline style={{ display: 'flex', marginLeft: '6px' }}>
            <img style={{ width: '85px' }} src={Codepoint} />
          </Link>
        </div>
      </div>
    </VStack>
  </Block>
);

export default AuthLayout;
