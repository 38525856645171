import { XelaColor } from '@codepoint-pt/xela';
import styled from 'styled-components';

export const Description = styled.div`
  font-size: 14px;
  margin-top: 5px;
  
  &:last-child {
    margin-top: 0px;
  }
`;

export const DescriptionTitle = styled.span`
  margin-right: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryColor};
`;

export const DescriptionText = styled.span`
  color: #868e96;
`;

export const FinalUrlTitle = styled.span`
  margin-right: 5px;
  font-weight: bold;
  color: #000000;
  font-size: 15px;
`;

export const FinalUrl = styled.a`
  color: ${XelaColor.Blue5};
  font-size: 15px;
  cursor: pointer;
  transition: color 0.3s linear;

  &:hover {
    color: ${XelaColor.Blue1};
  }
`;