import { Menu, MenuItemProps } from '@mantine/core';
import { transitionsDelay } from '../../styles/BasicStyles';
import { XelaColor } from '@codepoint-pt/xela';
import styled, { css } from 'styled-components';

export const StyledDropdown = styled(Menu.Dropdown)`
  &.mantine-Menu-dropdown {
    background: ${XelaColor.Gray11};
    border-radius: 8px;
    border: 0;
    padding: 0;
    min-width: 135px;
  }
`;

export const StyledItem = styled(Menu.Item)<MenuItemProps & { key: number, onClick?: () => void }>`
  padding: 12px 16px;
  background-color: transparent !important;
  font-family: 'Inter', sans-serif;
  cursor: ${({ onClick }) => !!onClick ? 'pointer' : 'initial'};

  & span {
    transition: color ${transitionsDelay} linear;
    color: ${XelaColor.Gray4};
  }

  ${({ onClick }) => !!onClick && 
    css`
      &:hover {
        & span {
          color: ${XelaColor.Blue4};
        }
      }
    `
  }
`;

export const StyledDivider = styled(Menu.Divider)<MenuItemProps & { key: number }>`
  border-top: 1px solid ${XelaColor.Gray10};
  margin: 0;
`;
