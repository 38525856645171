import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Municipality } from '../../models/Municipality';
import useFetch from 'use-http';

const MunicipalitiesListPage = DefaultListPage<Municipality>();

const MunicipalitiesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { get } = useFetch('/municipalities');

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true
    }
  ];

  return (
    <MunicipalitiesListPage
      translate={t}
      request={{
        get,
        searchFields: ['name']
      }}
      storage="MUNICIPALITIES_FILTERS"
      header={{
        navigate,
        title: t('MUNICIPALITIES'),
        breadcrumbs: [{ title: t('MUNICIPALITIES'), href: '/municipalities' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/municipalities/${row._id}`)
      }}
    />
  );
};

export default MunicipalitiesPage;
