import {
  BaseButton,
  HStack,
  Icon,
  IconProps,
  ScrollArea,
  showNotification as mantineNotification,
  hideNotification,
  VStack
} from '@codepoint-pt/xela';
import { Translation } from 'react-i18next';

interface ErrorProps {
  title: string | React.ReactElement;
  message: string | React.ReactElement;
  id?: string;
  autoClose?: number | false;
  color?: string;
  icon?: IconProps['icon'];
}
type ErrorFunction = (props: ErrorProps) => void;
const ErrorContent = (message: string | React.ReactElement) => (
  <ScrollArea.Autosize maxHeight={120} scrollbarSize={5} type="auto">
    {message}
  </ScrollArea.Autosize>
);
export const showError: ErrorFunction = ({
  title,
  message,
  color = 'red',
  autoClose = 5000,
  icon = 'essentials_shieldX'
}) =>
  mantineNotification({
    autoClose,
    title,
    message: ErrorContent(message),
    color,
    icon: <Icon icon={icon} size={14} color="white" />,
    radius: 'xl'
  });

export const showSuccess: ErrorFunction = ({
  title,
  message,
  color = 'green',
  autoClose = 5000,
  icon = 'essentials_shieldTick'
}) =>
  mantineNotification({
    autoClose,
    title,
    message: ErrorContent(message),
    color,
    icon: <Icon icon={icon} size={14} color="white" />,
    radius: 'xl'
  });

export const showWarning: ErrorFunction = ({
  title,
  message,
  color = 'yellow',
  autoClose = 5000,
  icon = 'basics_exclamationCircle'
}) =>
  mantineNotification({
    autoClose,
    title,
    message: ErrorContent(message),
    color,
    icon: <Icon icon={icon} size={14} color="white" />,
    radius: 'xl'
  });

interface ConfirmProps extends ErrorProps {
  onConfirm: () => void;
  confirmTag?: string;
}
type ConfirmFunction = (props: ConfirmProps) => void;
const ConfirmContent = (
  message: string | React.ReactElement,
  id: string,
  onConfirm: () => void,
  confirmTag: string,
  color?: string
) => (
  <ScrollArea.Autosize maxHeight={120} scrollbarSize={5} type="auto">
    <VStack spacing="5px">
      <VStack>{message}</VStack>
      <HStack justifyContent="flex-end" spacing="12px">
        <BaseButton
          size="xs"
          onClick={() => hideNotification(id)}
          action="default"
        >
          <Translation>{(t) => <span>{t('CANCEL')}</span>}</Translation>
        </BaseButton>
        <BaseButton
          size="xs"
          action="secondary"
          color={color}
          onClick={() => {
            hideNotification(id);
            onConfirm();
          }}
        >
          <Translation>{(t) => <span>{t(confirmTag)}</span>}</Translation>
        </BaseButton>
      </HStack>
    </VStack>
  </ScrollArea.Autosize>
);
export const showDelete: ConfirmFunction = ({
  id = 'delete_popup',
  title,
  message,
  autoClose = 5000,
  color = 'red',
  icon = 'essentials_bin',
  onConfirm,
  confirmTag = 'DELETE'
}) =>
  mantineNotification({
    id,
    autoClose,
    disallowClose: true,
    title,
    message: ConfirmContent(message, id, onConfirm, confirmTag, color),
    icon: <Icon icon={icon} size={14} color="white" />,
    color,
    radius: 'xl'
  });

export const showConfirm: ConfirmFunction = ({
  id = 'confirm_popup',
  title,
  message,
  autoClose = 5000,
  color = 'primary',
  icon = 'basics_info',
  onConfirm,
  confirmTag = 'CONFIRM'
}) =>
  mantineNotification({
    id,
    autoClose,
    disallowClose: true,
    title,
    message: ConfirmContent(message, id, onConfirm, confirmTag, color),
    icon: <Icon icon={icon} size={14} color="white" />,
    color,
    radius: 'xl'
  });
