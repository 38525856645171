import { Navigate } from 'react-router-dom';
import { UserContext } from '../../Context';
import { FC, useContext } from 'react';
import Layout from '../../components/layout/Layout';

const AuthenticatedRoute: FC<{
  privileged?: boolean,
  roles?: string[],
  children: JSX.Element
}> = ({ children, roles, privileged }) => {
  const { user } = useContext(UserContext);
  if (!user) {
    return <Navigate replace to="/login" />;
  }
  if (privileged && !user?.superuser) {
    return <Navigate replace to="/" />;
  }
  if(roles && roles.length > 0 && !roles.includes(user.type || '')) {
    return <Navigate replace to="/" />;
  }
  return <Layout>{children}</Layout>;
};

export default AuthenticatedRoute;
