/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AnyObject,
  GenericInput,
  Grid,
  AspectRatios,
  InputWrapper,
  ActionIcon,
  Icon
} from '@codepoint-pt/xela';
import { FC } from 'react';
import styled from 'styled-components';
import ImageInput from './ImageInput';

const BackgroundImage = styled.div<{
  src: string;
  $color?: string;
  ratio?: number;
}>`
  position: relative;
  width: 100%;
  padding-bottom: ${({ ratio }) => (ratio ? 100 * ratio : 100)}%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ $color }) => $color || 'transparent'};
  border-radius: 16px;
`;

export interface ImageListInputProps {
  input: GenericInput<AnyObject[]>;
  meta: AnyObject;
  label?: string;
  ratio?: number;
}

const ImageListInput: FC<ImageListInputProps> = ({
  input,
  meta,
  label,
  ratio = AspectRatios['16:9']
}) => {
  const images = Array.isArray(input.value) ? input.value : [];

  const addNewImage = (image: any) => {
    const imgs = [...images];
    imgs.push(image);
    input.onChange(imgs);
  };

  const removeImage = (index: number) => {
    const imgs = [...images];
    imgs.splice(index, 1);
    input.onChange(imgs);
  };

  return (
    <InputWrapper label={label}>
      <Grid gutter={24}>
        {images.map((img, index) => (
          <Grid.Col key={index} xs={4}>
            <BackgroundImage
              ratio={ratio}
              $color={img?.color}
              src={img.preview || img.url}
            >
              <ActionIcon
                color="red"
                variant="filled"
                radius="md"
                style={{ position: 'absolute', top: '5px', right: '5px' }}
                onClick={() => removeImage(index)}
              >
                <Icon icon="essentials_bin" color="white" size={14} />
              </ActionIcon>
            </BackgroundImage>
          </Grid.Col>
        ))}
        <Grid.Col xs={4}>
          <ImageInput
            meta={meta}
            input={{
              value: undefined,
              onChange: addNewImage
            }}
            ratio={ratio}
            maxSize={2000000}
            maxSizeText='2MB'
          />
        </Grid.Col>
      </Grid>
    </InputWrapper>
  );
};

export default ImageListInput;
