import { Block, Divider } from '@codepoint-pt/xela';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Passport, User } from '../../../models/User';
import { ACTIVE, Poi } from '../../../models/Poi';
import { BlockContent, BlockSection, BlockSectionText, BlockTitle, NoStampsText, PassportCompleted, SummaryWrapper, Totals } from './styles';
import dayjs from 'dayjs';

interface PassportTabProps {
  initialValues?: User;
  pois: Poi[];
}

const PassportTab: FC<PassportTabProps> = ({ initialValues, pois }) => {
  const { t, i18n: { language } } = useTranslation();
  const hasPassport = pois.filter(elem => elem.passport && elem.status === ACTIVE);

  // For Passport to be completed, we need to count how many Pois have Passport and how many the User has (doesn't matter if he completed the Passport in the past before)
  const isPassportCompleted = () => {
    if(!!initialValues?.passport_completed && hasPassport.length === initialValues?.passport?.length) return `${dayjs(initialValues?.passport_completed).format('DD/MM/YYYY, HH:mm')}h`;
    return 'N/A';
  };

  return (
    <Block
      borderRadius="16px"
      bg="white"
      padding="50px 15px"
      margin="10px 0"
    >
      <Block margin="0 auto" style={{ maxWidth: '700px' }}>
        {
          initialValues?.passport && initialValues?.passport?.length > 0 ?
          <>
            <SummaryWrapper>
              <PassportCompleted>
                {t('PASSPORT_COMPLETED')}: <span>{isPassportCompleted()}</span>
              </PassportCompleted>
              <Totals>
                {`${initialValues?.passport?.length} / ${hasPassport.length}`}
              </Totals>
            </SummaryWrapper>
            <Divider style={{ marginBottom: '15px' }} />
            {
              initialValues.passport.map((elem: Passport, index: number) =>
                <BlockContent key={index} index={index} color={elem.poi?.category?.color}>
                  <BlockTitle>
                    <BlockSectionText>
                      {elem.poi?.name[language]}
                    </BlockSectionText>
                  </BlockTitle>
                  <Divider />
                  <BlockSection>
                    <BlockSectionText>
                      {`${elem.poi?.category?.name[language]} ${elem.createdAt ? `- ${dayjs(elem.createdAt).format('DD/MM/YYYY, HH:mm')}h`: ''}`}
                    </BlockSectionText>
                  </BlockSection>
                </BlockContent>
              )
            }
          </>
          :
          <NoStampsText>{t('NO_STAMPS')}</NoStampsText>
        }
      </Block>
    </Block>
  );
};

export default PassportTab;
