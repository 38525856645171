import { ActionIcon, Menu, Icon, Divider } from '@codepoint-pt/xela';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import TextInterpolation from '../../../../components/text/TextInterpolation';
import { AnyObject } from '../../../../models/Generic';
import { DayBlock } from '../../../../models/Itinerary';

const BlockContent = styled.div`
  margin-top: 30px;
  position: relative;
  background-color: #eef2ff;
  padding: 10px;
  border-radius: 16px;
`;
const BlockTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
  color: #000000;
  align-items: center;
`;

const BlockSection = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const BlockSectionText = styled.div`
  margin-left: 5px;
  display: flex;
`;
interface Params {
  block: DayBlock;
  t: (v: string) => string;
  lang: string;
  handleEdit: () => void;
  handleDelete: () => void;
  moveBlock: (id: string, atIndex: number) => void;
  findCard: (_id: string) => AnyObject;
}
const DraggableBlock = ({
  block,
  t,
  lang,
  handleEdit,
  handleDelete,
  moveBlock,
  findCard
}: Params) => {
  const { _id } = block;
  const originalIndex = findCard(_id).index;
  const [{}, drag, preview] = useDrag(
    () => ({
      type: 'block',
      item: { id: _id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
      end: ({ id: droppedId, originalIndex }, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveBlock(droppedId, originalIndex);
        }
      }
    }),
    [_id, originalIndex, moveBlock]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'block',
      hover({ id: draggedId }: AnyObject) {
        if (draggedId !== _id) {
          const { index: overIndex } = findCard(_id);
          moveBlock(draggedId, overIndex);
        }
      }
    }),
    [findCard, moveBlock]
  );

  return (
    <BlockContent ref={(node) => preview(drop(node))}>
      <Menu trigger="hover" closeOnItemClick position="bottom-end">
        <Menu.Target>
          <ActionIcon
            variant="light"
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px'
            }}
          >
            <Icon icon="interface_moreVertical" size={18} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item ref={drag} icon={<Icon icon="authoring_drag" />}>
            {t('DRAG')}
          </Menu.Item>
          <Menu.Item
            icon={<Icon icon="essentials_edit" />}
            onClick={handleEdit}
          >
            {t('EDIT')}
          </Menu.Item>
          <Menu.Item
            color="red"
            icon={<Icon icon="essentials_bin" color="red" />}
            onClick={handleDelete}
          >
            {t('DELETE')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <BlockTitle>
        <BlockSectionText>
          {t('DAY')} {originalIndex + 1} - {block.km}Km
        </BlockSectionText>
      </BlockTitle>
      <Divider></Divider>
      <BlockSection>
        <BlockSectionText>
          <TextInterpolation html={block.html[lang]} />
        </BlockSectionText>
      </BlockSection>
    </BlockContent>
  );
};

export default DraggableBlock;
