import { HeaderBaseAction, Icon, LoadingOverlay, PageHeader, XelaColor } from '@codepoint-pt/xela';
import { useContext, useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { InfoContext } from '../../Context';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../styles/BasicStyles';
import { Event } from '../../models/Event';
import { Poi } from '../../models/Poi';
import { Itinerary } from '../../models/Itinerary';
import { EVENT, ITINERARY, LINK, NOT_SENT, Notification, POI } from '../../models/Notification';
import { showConfirm, showDelete, showSuccess, showError } from '../../hooks/show-notification/show-notification';
import yup, { isMongoId } from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import FormFields from './components/formFields';
import StatusComponent from './components/status';
import AlertComponent from './components/alert';

const { Form } = withTypes<Notification>();

const CreateNotificationPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showAlert, setAlert] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Notification>();
  const [country, setCountry] = useState<string>('');
  const [interest, setInterest] = useState<string>('');
  const [currentUsers, setCurrentUsers] = useState<number>(0);
  const [pois, setPois] = useState<Poi[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { info } = useContext(InfoContext);
  const intlObject = useIntlValidation();
  const { get, post, put, del } = useFetch('/notifications');
  const { get: getUsers } = useFetch('/users/calculate-total');
  const { get: getPois } = useFetch('/pois/dropdown');
  const { get: getEvents } = useFetch('/events/dropdown');
  const { get: getItineraries } = useFetch('/itineraries/dropdown');

  useEffect(() => {
    const init = async () => {
      if(id) {
        const { data, success } = await get(`/${id}`);
        if(success) {
          setInitialValues({
            ...data,
            country: data?.country?._id,
            interest: data?.interest?._id,
            poi: data?.poi?._id,
            event: data?.event?._id,
            itinerary: data?.itinerary?._id
          });
        }
      }

      const poisResult = await getPois();
      if(poisResult?.success) setPois(poisResult.data || []);

      const eventsResult = await getEvents();
      if(eventsResult?.success) setEvents(eventsResult.data || []);

      const itinerariesResult = await getItineraries();
      if(itinerariesResult?.success) setItineraries(itinerariesResult.data || []);

      setLoading(false);
    };

    init();
  }, [id]);

  useEffect(() => {
    if(id && location.search.includes('showAlert')) {
      setAlert(true);
      navigate(`/notifications/${id}`, { replace: true });
    }
  }, [id, location.search]);

  useEffect(() => {
    const calculateUsers = async () => {
      const { data, success } = await getUsers(`?country=${country}&interest=${interest}`);
      if(success) setCurrentUsers(data);
    };

    calculateUsers();
  }, [country, interest]);

  const onSubmit = async (values: Notification) => {
    if(showAlert) setAlert(false);
    
    if(currentUsers === 0) {
      return showError({
        title: t('ERROR'),
        message: t('NO_USERS_NOTIFICATION')
      });
    }

    if(id) {
      const { success } = await put(`/${values._id}`, values);
      if(success) navigate('/notifications');
    } 
    else {
      const { success, data } = await post(values);
      if(success) navigate(`/notifications/${data._id}?showAlert`);
    }
  };

  const handleSend = () => {
    if(showAlert) setAlert(false);

    showConfirm({
      title: t('CONFIRM'),
      message: t('SEND_NOTIFICATION_CONFIRM'),
      onConfirm: async () => {
        setSending(true);

        const { success } = await get(`/${id}/send`);
        refreshStatus();

        if(success) {
          showSuccess({
            title: t('SUCCESS'),
            message: t('NOTIFICATION_SEND_START')
          });
        }

        setSending(false);
      }
    });
  };

  const onDelete = async () => {
    if(showAlert) setAlert(false);

    if(id) {
      setLoading(true);
      const { success } = await del(`/${id}`);

      if(success) navigate('/notifications');
      else setLoading(false);
    }
  };

  const refreshStatus = async () => {
    setLoading(true);

    const { data, success } = await get(`/${id}`);
    if(success) {
      setInitialValues({
        ...data,
        country: data?.country?._id,
        interest: data?.interest?._id,
        poi: data?.poi?._id,
        event: data?.event?._id,
        itinerary: data?.itinerary?._id
      });
    }

    setLoading(false);
  };

  const buildActionButtons = (submitting: boolean, pristine: boolean) => {
    const headerActions: HeaderBaseAction[] = [
      {
        name: t('BACK'),
        onClick: () => navigate('/notifications'),
        action: 'secondary',
        color: 'gray',
        variant: 'light',
        icon: <Icon icon='basics_left' size={12} color={XelaColor.Gray9} />
      }
    ];
  
    if(!id || initialValues?.state?.status === NOT_SENT) {
      headerActions.push({
        name: t('SAVE'),
        variant: 'outline',
        type: 'submit',
        loading: submitting,
        disabled: pristine,
        icon: <Icon icon="essentials_save" size={12} color="white" />
      });
    }
  
    if(initialValues?.state?.status === NOT_SENT) {
      headerActions.push({
        name: t('SEND'),
        onClick: handleSend,
        action: 'secondary',
        color: 'green',
        loading: isSending,
        disabled: isSending || !pristine,
        icon: <Icon icon='essentials_direction' size={12} color='white' />
      });

      headerActions.push({
        name: t('DELETE'),
        action: 'error',
        variant: 'light',
        icon: <Icon icon="essentials_bin" size={12} color="red" />,
        onClick: () =>
          showDelete({
            title: t('DELETE_ENTITY'),
            message: t('DELETE_ENTITY_MESSAGE'),
            onConfirm: onDelete
          })
      });
    }

    return headerActions;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          text: intlObject({ required: true }).required(),
          poi: isMongoId.when('link_type', ([link_type], schema) => { return link_type === POI ? schema.required() : schema; }),
          event: isMongoId.when('link_type', ([link_type], schema) => { return link_type === EVENT ? schema.required() : schema; }),
          itinerary: isMongoId.when('link_type', ([link_type], schema) => { return link_type === ITINERARY ? schema.required() : schema; }),
          link: yup.string().when('link_type', ([link_type], schema) => { return link_type === LINK ? schema.required() : schema; })
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_NOTIFICATION' : 'ADD_NOTIFICATION')}
            onBack={() => navigate('/notifications')}
            breadcrumbs={[
              { title: t('NOTIFICATIONS'), href: '/notifications' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={buildActionButtons(submitting, pristine)}
          />
          <AlertComponent 
            showAlert={showAlert} 
          />
          <StatusComponent 
            initialValues={initialValues} 
            refreshStatus={refreshStatus}
          />
          <FormFields 
            pois={pois}
            events={events}
            itineraries={itineraries}
            countries={info.countries}
            interests={info.interests}
            subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)}
            disableFields={!!id && initialValues?.state?.status !== NOT_SENT}
            setCountry={setCountry}
            setInterest={setInterest}
            currentUsers={currentUsers}
            initialValues={initialValues}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default CreateNotificationPage;
