import { Badge, DateInput, DefaultListPage, Grid, SearchInput, SelectInput, XelaColor } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { User } from '../../models/User';
import { AnyObject } from '../../models/Generic';
import { useContext } from 'react';
import { InfoContext } from '../../Context';
import dayjs from 'dayjs';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import IntlSelectInput from '../../components/inputs/IntlSelectInput';

const UserListPage = DefaultListPage<User>();

const statusOptions = [
  { _id: 1, name: 'ACTIVE'},
  { _id: 2, name: 'INACTIVE'}
];

const UsersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { info } = useContext(InfoContext);
  const { get } = useFetch('/users');

  const columns = [
    { title: t('NAME'), dataIndex: 'name', sortable: true },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      sortable: true
    },
    {
      title: t('BIRTHDATE'),
      dataIndex: 'birth',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : 'N/A'
    },
    {
      title: t('GENDER'),
      dataIndex: 'gender',
      sortable: true,
      render: (value: AnyObject) => 
        value ? <ActiveTranslation value={value?.name} /> : 'N/A'
    },
    {
      title: t('NATIONALITY'),
      dataIndex: 'country',
      sortable: true,
      render: (value: AnyObject) =>
        value ? <ActiveTranslation value={value?.name} /> : 'N/A'
    },
    {
      title: t('CITY'),
      dataIndex: 'city',
      sortable: true,
      render: (value: string) => value || 'N/A'
    },
    {
      title: t('REGISTERDATE'),
      dataIndex: 'createdAt',
      sortable: true,
      render: (value: string) => value ? dayjs(value).format('HH:mm DD/MM/YYYY') : 'N/A'
    },
    {
      title: t('ACTIVE'),
      dataIndex: 'active',
      render: (value: boolean) => (
        <Badge
          label={value ? t('YES') : t('NO')}
          textColor='#ffffff'
          bg={value ? XelaColor.Green3 : XelaColor.Red3}
        />
      )
    }
  ];

  return (
    <UserListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email']
      }}
      storage="USER_FILTERS"
      header={{
        navigate,
        title: t('USERS'),
        breadcrumbs: [{ title: t('USERS'), href: '/users' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={3}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <DateInput 
              placeholder={t('SELECT_BIRTHDATE')}
              input={{
                value: filters.birth,
                onChange: (v: string | null) => handleChange('birth', v, 1000)
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput 
              placeholder={t('SELECT_GENDER')}
              input={{
                value: filters.gender,
                onChange: (v: string | null) => handleChange('gender', v, 1000)
              }}
              data={info.genders}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_COUNTRY')}
              input={{
                value: filters.country,
                onChange: (v: string | null) => handleChange('country', v, 1000)
              }}
              data={info.countries}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <IntlSelectInput
              placeholder={t('SELECT_INTEREST')}
              input={{
                value: filters.interest,
                onChange: (v: string | null) => handleChange('interest', v, 1000)
              }}
              data={info.interests}
              clearable
            />
          </Grid.Col>
          <Grid.Col xs={3}>
            <SelectInput
              placeholder={t('SELECT_STATUS')}
              input={{
                value: filters.active,
                onChange: (v: string | null) => handleChange('active', v, 1000)
              }}
              data={statusOptions}
              translate={t}
              clearable
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/users/${row._id}`)
      }}
    />
  );
};

export default UsersPage;
