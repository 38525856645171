import { ADMIN } from '../../models/User';
import ManagePoisPage from '../../pages/pois/ManagePoisPage';
import PoisPage from '../../pages/pois/PoisPage';
import ManageItineraryPage from '../../pages/itineraries/ManageItineraryPage';
import ItinerariesPage from '../../pages/itineraries/ItinerariesPage';
import MunicipalitiesPage from '../../pages/municipalities/MunicipalitiesPage';
import ManageMunicipalityPage from '../../pages/municipalities/ManageMunicipalityPage';
import NewsPage from '../../pages/news/NewsPage';
import ManageNewsPage from '../../pages/news/ManageNewsPage';
import EventsPage from '../../pages/events/EventsPage';
import ManageEventPage from '../../pages/events/ManageEventPage';
import NotificationsPage from '../../pages/notifications/NotificationsPage';
import ManageNotificationPage from '../../pages/notifications/ManageNotificationPage';
import DraftsPage from '../../pages/drafts/ListPage';

const EntityRoutes = [
  {
    path: '/pois',
    component: <PoisPage />,
    isAuthenticated: true
  },
  {
    path: '/pois/new',
    component: <ManagePoisPage />,
    isAuthenticated: true
  },
  {
    path: '/pois/:id',
    component: <ManagePoisPage />,
    isAuthenticated: true
  },
  {
    path: '/itineraries',
    component: <ItinerariesPage />,
    isAuthenticated: true
  },
  {
    path: '/itineraries/new',
    component: <ManageItineraryPage />,
    isAuthenticated: true
  },
  {
    path: '/itineraries/:id',
    component: <ManageItineraryPage />,
    isAuthenticated: true
  },
  {
    path: '/municipalities',
    component: <MunicipalitiesPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/municipalities/:id',
    component: <ManageMunicipalityPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/blog',
    component: <NewsPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/blog/new',
    component: <ManageNewsPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/blog/:id',
    component: <ManageNewsPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/events',
    component: <EventsPage />,
    isAuthenticated: true
  },
  {
    path: '/events/new',
    component: <ManageEventPage />,
    isAuthenticated: true
  },
  {
    path: '/events/:id',
    component: <ManageEventPage />,
    isAuthenticated: true
  },
  {
    path: '/notifications',
    component: <NotificationsPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/notifications/new',
    component: <ManageNotificationPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/notifications/:id',
    component: <ManageNotificationPage />,
    isAuthenticated: true,
    roles: [ADMIN]
  },
  {
    path: '/drafts',
    component: <DraftsPage />,
    isAuthenticated: true
  },
  {
    path: '/drafts/event/:id',
    component: <ManageEventPage />,
    isAuthenticated: true
  },
  {
    path: '/drafts/poi/:id',
    component: <ManagePoisPage />,
    isAuthenticated: true
  },
  {
    path: '/drafts/itinerary/:id',
    component: <ManageItineraryPage />,
    isAuthenticated: true
  }
];

export default EntityRoutes;
