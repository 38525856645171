/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, withTypes } from 'react-final-form';
import { BasicForm } from '../../../styles/BasicStyles';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import {
  Modal,
  XelaColor,
  Grid,
  TextAreaInput,
  Typography,
  BaseButton,
  Icon
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { EVENT, ITINERARY, POI } from '../../../models/Draft';
import { showWarning } from '../../../hooks/show-notification/show-notification';
import yup from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';

interface Params {
  opened: boolean;
  type?: typeof EVENT | typeof ITINERARY | typeof POI;
  title?: string;
  id?: string;
  onClose: (changed: boolean) => void;
}

const { Form } = withTypes<{ reason: string }>();

const RejectModal = ({ opened, type, title, id, onClose }: Params) => {
  const { t } = useTranslation();
  const { put } = useFetch(`/drafts/${type?.toLowerCase()}`);

  const onSubmit = async (values: { reason: string }) => {
    let error = false;
    
    const { success } = await put(`/${id}/reject`, values);
    if(success) {
      onClose(true);

      showWarning({
        title: t('SUCCESS'),
        message: t('REJECTED_MESSAGE'),
        autoClose: 10000
      });
    }
    else error = true;

    return !error;
  };

  const renderTitle = () => {
    if(type === EVENT) return 'REJECT_EVENT';
    if(type === ITINERARY) return 'REJECT_ITINERARY';
    if(type === POI) return 'REJECT_POI';
    return '';
  };

  return (
    <Modal
      overlayColor={XelaColor.Gray11}
      padding="xl"
      size="xl"
      centered
      opened={opened}
      onClose={() => onClose(false)}
      title={t(renderTitle())}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(
          yup.object({
            reason: yup.string().required()
          })
        )}
      >
        {({ handleSubmit, form: { reset } }) => {
          return (
            <BasicForm onSubmit={async (event: any) => {
              const result = await handleSubmit(event);
              if(result) reset();
            }}>
              <Grid style={{ marginBottom: '0.5rem' }}>
                <Grid.Col xs={12}>
                  <Typography variant='body-small'>{t('TITLE')}: <span style={{ fontWeight: 600 }}>{title}</span></Typography>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <Field name="reason">
                    {(props) => (
                      <TextAreaInput
                        {...props}
                        label={`${t('REASON')} *`}
                        placeholder={t('ENTER_REASON')}
                      />
                    )}
                  </Field>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12} style={{ textAlign: 'right' }}>
                  <BaseButton
                    type="submit"
                    leftIcon={<Icon color="white" icon="essentials_save" />}
                  >
                    {t('SAVE')}
                  </BaseButton>
                </Grid.Col>
              </Grid>
            </BasicForm>
          );
        }}
      </Form>
    </Modal>
  );
};

export default RejectModal;
