import {
  Block,
  Grid,
  Icon,
  Tabs,
  TextAreaInput,
  TextInput,
  XelaColor,
} from '@codepoint-pt/xela';
import { useContext } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../Context';
import RteTextInput from './RteTextInput';

interface Validations {
  maxChar?: number;
}

interface TranslatableField {
  label: string;
  name: string;
  type: 'text' | 'textarea' | 'richtext';
  validations?: Validations;
  disabled?: boolean;
  required?: boolean;
  allowImages?: boolean;
}

const TranslatableFields: React.FC<{
  fields: TranslatableField[];
  subValidate: (fields: string[]) => boolean;
  keepMounted?: boolean;
}> = ({ fields, subValidate, keepMounted = false }) => {
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  return (
    <Tabs radius="md" defaultValue="pt" keepMounted={keepMounted}>
      <Tabs.List>
        {languages.map((lang) => (
          <Tabs.Tab
            key={lang.code}
            icon={<img style={{ width: '18px' }} src={lang.icon} />}
            value={lang.code}
            rightSection={
              subValidate(
                fields.map((field) => `${field.name}.${lang.code}`)
              ) && (
                <Icon icon="basics_exclamationCircle" color={XelaColor.Red5} />
              )
            }
          >
            {t(lang.tag)}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {languages.map((lang) => (
        <Tabs.Panel key={lang.code} value={lang.code}>
          <Block margin="15px 0">
            <Grid gutter={24}>
              {fields.map((field) => (
                <Grid.Col key={field.name} xs={12}>
                  <Field name={`${field.name}.${lang.code}`}>
                    {(props) => {
                      if (field.type === 'text') {
                        return (
                          <TextInput
                            {...props}
                            label={t(field.label)}
                            placeholder={
                              field.disabled
                                ? ''
                                : t(`INPUT_${field.label}_PLACEHOLDER`)
                            }
                            maxChar={field?.validations?.maxChar}
                            disabled={field.disabled}
                            withAsterisk={field.required}
                          />
                        );
                      }
                      if (field.type === 'textarea') {
                        return (
                          <TextAreaInput
                            {...props}
                            label={t(field.label)}
                            placeholder={t(`INPUT_${field.label}_PLACEHOLDER`)}
                            maxChar={field?.validations?.maxChar}
                            autosize
                            disabled={field.disabled}
                            withAsterisk={field.required}
                          />
                        );
                      }
                      if (field.type === 'richtext') {
                        return (
                          <RteTextInput
                            {...props}
                            label={t(field.label)}
                            placeholder={t(`INPUT_${field.label}_PLACEHOLDER`)}
                            withAsterisk={field.required}
                            allowImages={field.allowImages}
                          />
                        );
                      }
                    }}
                  </Field>
                </Grid.Col>
              ))}
            </Grid>
          </Block>
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};

export default TranslatableFields;
