import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StatusLabel = styled.div`
  font-size: 15px;
`;

export const Status = styled.span<{ color?: string }>`
  font-weight: bold;
  color: ${({ color }) => color};
`;

export const DateLabel = styled.div`
  font-size: 13px;
  margin-top: 5px;

  & span {
    font-weight: bold;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const TotalUsers = styled.div`
  font-size: 15px;
  
  & span {
    font-weight: bold;
  }
`;
