import EN from './english_flag.svg';
import FR from './france_flag.svg';
import ES from './spanish_flag.svg';
import PT from './portuguese_flag.svg';

export const Languages = [
  { code: 'pt', name: 'Português', tag: 'PORTUGUESE', icon: PT },
  { code: 'en', name: 'English', tag: 'ENGLISH', icon: EN },
  { code: 'es', name: 'Español', tag: 'SPANISH', icon: ES },
  { code: 'fr', name: 'Français', tag: 'FRENCH', icon: FR }
];

export const AvailableLanguages = (list: string[] = []) =>
  Languages.filter((l) => list.includes(l.code));

export const RestaurantLanguages = [
  { _id: 'pt', name: 'Português' },
  { _id: 'en', name: 'English' },
  { _id: 'es', name: 'Español' },
  { _id: 'fr', name: 'Français' },
  { _id: 'it', name: 'Italiano' }
];
