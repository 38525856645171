import { AWAITING_VALIDATION, REJECTED } from './Draft';
import { AnyObject } from './Generic';
import { Interest } from './Interest';
import { Municipality } from './Municipality';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

type EventStatus = typeof ACTIVE | typeof INACTIVE | typeof AWAITING_VALIDATION | typeof REJECTED;

export const EventStatusList = [
  { _id: ACTIVE, name: ACTIVE, bgColor: 'rgba(76, 175, 80, 0.12)', fontColor: '#4CAF50' },
  { _id: INACTIVE, name: INACTIVE, bgColor: 'rgba(221, 64, 64, 0.1)', fontColor: '#DD4040' }
];

export interface Event {
  readonly _id: string;
  slug: string;
  title: AnyObject;
  municipality?: Municipality;
  start_datetime: Date;
  end_datetime: Date;
  description?: AnyObject;
  photo: AnyObject;
  images?: AnyObject[];
  website?: string;
  interests?: Interest[];
  address?: string;
  coordinates?: {
    latitude: string;
    longitude: string;
  };
  status: EventStatus;
  metadata?: {
    title: AnyObject;
    description: AnyObject;
    keywords: AnyObject;
  };
  draft?: AnyObject;
}
