import { Block, HeaderBaseAction, Icon, LoadingOverlay, PageHeader, XelaColor, toFormData } from '@codepoint-pt/xela';
import { InfoContext, UserContext } from '../../Context';
import { useContext, useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { ACTIVE, INACTIVE, Poi } from '../../models/Poi';
import { BasicForm } from '../../styles/BasicStyles';
import { ValidationErrors } from 'final-form';
import { Category } from '../../models/Category';
import { AnyObject } from '../../models/Generic';
import { getFormDataSize } from '../../utils/files/Size';
import { showConfirm, showError, showSuccess, showWarning } from '../../hooks/show-notification/show-notification';
import { RequiredFieldsWrapper } from './tabs/styles';
import { ADMIN, MUNICIPALITY_ADMIN } from '../../models/User';
import { EVENT, ITINERARY, POI, REJECTED } from '../../models/Draft';
import { renderAlert, renderStatus } from '../../utils/draft';
import yup, { isCoordinate, isContact, isMongoId, isSlug, isUrl } from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import MapInput from '../../components/inputs/MapInput';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import ContactsTab from './tabs/ContactsTab';
import InformationTab from './tabs/InformationTab';
import MultimediaTab from './tabs/MultimediaTab';
import MetaDataTab from '../../components/metadata';
import RejectModal from '../drafts/components/RejectModal';

const { Form } = withTypes<Poi>();

// Categories that have Sub Categories (Sleep, Visit and Fun)
const mainList = ['641d8c501d2d1fbb9f5ad200', '641d8c501d2d1fbb9f5ad202', '64a6c4793d9f6d58b15d6761'];

const ManagePoisPage = () => {
  const [loading, setLoading] = useState(true);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Poi>();
  const [extraFilter, setExtraFilter] = useState<string>('');
  const [subcategories, setSubcategories] = useState<Category[]>([]);
  const [rejectModal, setRejectModal] = useState<{ open: boolean; type?: typeof EVENT | typeof ITINERARY | typeof POI; title?: string; id?: string; }>({ open: false });
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { info } = useContext(InfoContext);
  const { user } = useContext(UserContext);
  const intlObject = useIntlValidation();
  const poiHook = useFetch('/pois');
  const draftHook = useFetch('/drafts/poi');
  const isDraft = location.pathname.includes('/drafts/poi/');

  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = async () => {
    if(id) {
      const { data, success } = isDraft ? await draftHook.get(`/${id}`) : await poiHook.get(`/${id}`);
      
      if(success) {
        if(user?.type === MUNICIPALITY_ADMIN && !user?.municipalities?.includes(data?.municipality?._id)) return navigate(isDraft ? '/drafts' : '/pois');

        setInitialValues({
          ...data,
          category: data?.category?._id,
          sub_category: data?.sub_category?._id,
          municipality: data?.municipality?._id
        });

        setExtraFilter(data?.category?.tag);

        const subList = info?.categories?.filter(elem => elem.parent?.toString() === data?.category?._id?.toString());
        setSubcategories(subList || []);
      }
    }

    setLoading(false);
  };

  const onSubmit = async (values: Poi) => {
    let success = false;

    // If we're missing a required field, change status to INACTIVE
    const isValid = checkStatus(values);

    // origin_poi is only needed when a Municipality Admin edits a production poi, we need to save that poi ID to link the poi and draft
    const payload = isDraft ? toFormData({ ...values, status: isValid ? ACTIVE : INACTIVE }) : toFormData({ ...values, origin_poi: id, status: isValid ? ACTIVE : INACTIVE });

    const formDataSize = getFormDataSize(payload);

    // Limit requests to 40MB
    if(formDataSize >= 40000000) {
      showError({
        title: t('ERROR'),
        message: t('REQUEST_MAX_SIZE')
      });

      return false;
    }

    if(id) {
      let result = null;

      // If the user is a Municipality Admin, when trying to edit a production poi, we need to create a Poi Draft instead
      if(user?.type === MUNICIPALITY_ADMIN && !isDraft) {
        result = await draftHook.post(payload);
      }
      else {
        result = isDraft ? await draftHook.put(`/${values._id}`, payload) : await poiHook.put(`/${values._id}`, payload);
      }

      success = result.success;
    } 
    else {
      const result = user?.type === MUNICIPALITY_ADMIN ? await draftHook.post(payload) : await poiHook.post(payload);
      success = result.success;
    }

    if(success) {
      /* 
      * Logic explained:
      * IF USER IS MUNICIPALITY ADMIN
      *   - New poi draft OR edit production poi » /drafts?success
      *   - Edit draft poi » /drafts
      * 
      * IF USER IS ADMIN
      *   - New or edit draft poi » /drafts
      *   - New or edit production poi » /pois
      */

      if(user?.type === MUNICIPALITY_ADMIN) {
        if(id && isDraft) navigate('/drafts');
        else navigate('/drafts?success');
      }
      else {
        if(isDraft) navigate('/drafts');
        else navigate('/pois');
      }

      if(user?.type === ADMIN && !isDraft) {
        if(isValid) {
          showSuccess({
            title: t('SUCCESS'),
            message: id ? t('POI_EDITED_PUBLISHED') : t('POI_CREATED_PUBLISHED')
          });
        }
        else {
          showWarning({
            title: t('WARNING'),
            message: id ? t('POI_EDITED_NOT_PUBLISHED') : t('POI_CREATED_NOT_PUBLISHED')
          });
        }
      }
    }
  };

  // Returns true if all required fields are filled, false otherwise
  const checkStatus = (values: AnyObject) => {
    let result = false;

    if(
      values.description?.pt &&
      values.description?.en &&
      values.description?.fr &&
      values.description?.es &&
      values.category &&
      (!mainList.includes(values.category) || (mainList.includes(values.category) && values.sub_category)) &&
      values.municipality &&
      values.coordinates?.latitude &&
      values.coordinates?.longitude &&
      values.slug
    ) {
      result = true;
    }

    return result;
  };

  const onDelete = async () => {
    if(id) {
      setLoading(true);

      const { success } = isDraft ? await draftHook.del(`/${id}`) : await poiHook.del(`/${id}`);
      if(success) navigate(isDraft ? '/drafts' : '/pois');
      else setLoading(false);
    }
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean, values: AnyObject) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: (
          <InformationTab
            categories={info?.categories}
            municipalities={info?.municipalities}
            subcategories={subcategories}
            setSubcategories={setSubcategories}
            extraFilter={extraFilter}
            setExtraFilter={setExtraFilter}
            subValidate={(fields) =>useSectionValidation(errors, fields, submitFailed)}
            user={user}
          />
        ),
        error: useSectionValidation(
          errors,
          ['name', 'description'],
          submitFailed
        )
      },
      {
        value: 'multimedia',
        label: t('MULTIMEDIA'),
        children: (
          <MultimediaTab
            subValidate={(fields) =>
              useSectionValidation(errors, fields, submitFailed)
            }
          />
        )
      },
      {
        value: 'coordinates',
        label: t('COORDINATES'),
        error: useSectionValidation(errors, ['coordinates'], submitFailed),
        children: (
          <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
            <Field name="coordinates">
              {(props) => (
                <MapInput
                  {...props}
                  label={t('COORDINATES')}
                  latitudeLabel={t('LATITUDE')}
                  latitudePlaceholder={t('INPUT_LATITUDE_PLACEHOLDER')}
                  longitudeLabel={t('LONGITUDE')}
                  longitudePlaceholder={t('INPUT_LONGITUDE_PLACEHOLDER')}
                  withAsterisk={false}
                />
              )}
            </Field>
          </Block>
        )
      },
      {
        value: 'contacts',
        label: t('CONTACTS'),
        children: <ContactsTab />,
        error: useSectionValidation(
          errors,
          ['address', 'contact', 'phone', 'email', 'website'],
          submitFailed
        )
      },
      {
        value: 'metadata',
        label: t('METADATA'),
        children: 
          <MetaDataTab 
            subValidate={(fields) => useSectionValidation(errors, fields, submitFailed)} 
            values={values} 
            section='poi' 
            withAsterisk={false} 
          />,
        error: useSectionValidation(
          errors,
          ['slug'],
          submitFailed
        )
      }
    ];

    return tabs;
  };

  const renderTitle = () => {
    if(id) {
      if(isDraft) return t('EDIT_DRAFT_POI');
      else return t('EDIT_POI');
    }
    else {
      if(isDraft) return t('ADD_DRAFT_POI');
      else return t('ADD_POI');
    }
  };

  const validatePoi = () => {
    showConfirm({
      title: t('CONFIRM'),
      message: t('VALIDATE_POI'),
      onConfirm: async () => {
        setLoadingValidate(true);

        const { success, data } = await draftHook.put(`/${id}/validate`);

        if(success && data?._id) {
          showSuccess({
            title: t('SUCCESS'),
            message: t('POI_VALIDATED_MESSAGE')
          });

          navigate(`/pois/${data._id}`);
        }

        setLoadingValidate(false);
      }
    });
  };

  const handleCloseRejectModal = (changed: boolean) => {
    if(changed) {
      setLoading(true);
      getInfo();
    }
    setRejectModal({ open: false });
  };

  const renderValidateActions = () => {
    const extra: HeaderBaseAction[] = [];

    if(user?.type === ADMIN && isDraft) {
      extra.push({
        name: t('VALIDATE'),
        onClick: validatePoi,
        action: 'secondary',
        color: 'green',
        variant: 'light',
        icon: <Icon icon="basics_tickCircle" size={12} color={XelaColor.Green1} />,
        loading: loadingValidate,
        disabled: loadingValidate
      });

      if(initialValues?.status !== REJECTED) {
        extra.push({
          name: t('REJECT'),
          onClick: () => setRejectModal({ open: true, type: POI, title: initialValues?.name[language], id: initialValues?._id }),
          action: 'secondary',
          color: 'orange',
          variant: 'light',
          icon: <Icon icon="basics_xCircle" size={12} color={XelaColor.Orange4} />,
          disabled: loadingValidate
        });
      }
    }

    return extra;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={useValidationSchema(
          yup.object({
            name: intlObject({ required: true }).required(),
            description: intlObject(),
            category: isMongoId.nullable(),
            sub_category: isMongoId.nullable(),
            municipality: user?.type === MUNICIPALITY_ADMIN ? isMongoId.required() : isMongoId.nullable(),
            coordinates: yup
              .object({
                latitude: isCoordinate,
                longitude: isCoordinate
              }),
            address: yup.string().nullable(),
            contact: yup.string().nullable(),
            phone: isContact,
            phone2: isContact,
            email: yup.string().email(),
            website: isUrl,
            website_reservation: isUrl,
            slug: isSlug
          })
        )}
      >
        {({ values, handleSubmit, submitting, pristine, errors, submitFailed }) => (
          <BasicForm onSubmit={handleSubmit}>
            <LoadingOverlay visible={loading} />
            <FormPrompt
              when={!pristine && !submitting && !loading}
              message={t('PROMPT_MESSAGE')}
            />
            <PageHeader
              navigate={navigate}
              title={renderTitle()}
              onBack={() => navigate(isDraft ? '/drafts' : '/pois')}
              breadcrumbs={[
                { title: t('POIS'), href: '/pois' },
                { title: t(id ? 'EDIT' : 'ADD') }
              ]}
              actions={headerActions({
                submitting,
                pristine,
                backLink: isDraft ? '/drafts' : '/pois',
                onDelete: user?.type === ADMIN && id ? onDelete : undefined,
                extra: renderValidateActions(),
                save: !isDraft && initialValues?.draft?._id ? false : true
              })}
            />
            { user?.type === ADMIN && !isDraft && initialValues?.draft?._id && renderAlert(t, navigate, 'POI_HAS_DRAFT_ADMIN', `/drafts/poi/${initialValues?.draft?._id}`) }
            { user?.type === MUNICIPALITY_ADMIN && !isDraft && initialValues?.draft?._id && renderAlert(t, navigate, 'POI_HAS_DRAFT', `/drafts/poi/${initialValues?.draft?._id}`) }
            { isDraft && renderStatus(t, initialValues?.status) }
            {
              /* Show this warning content if it's a NEW POI, or it's missing some required fields in EDIT POI */
              (!id || (id && !checkStatus(values))) &&
              <RequiredFieldsWrapper>
                <div className='title'>{t('POI_REQUIRED_FIELDS')}:</div>
                <div className='text'>{t('URL')},</div>
                <div className='text'>{t('DESCRIPTION')},</div>
                <div className='text'>{t('CATEGORY')},</div>
                <div className='text'>{t('SUBCATEGORY_IF_EXISTS')},</div>
                <div className='text'>{t('MUNICIPALITY')},</div>
                <div className='text'>{t('COORDINATES')}</div>
              </RequiredFieldsWrapper>
            }
            <BaseTabs
              baseTab="information"
              tabs={defineTabs(errors, submitFailed, values)}
            />
          </BasicForm>
        )}
      </Form>
      <RejectModal
        opened={rejectModal.open}
        type={rejectModal.type}
        title={rejectModal.title}
        id={rejectModal.id}
        onClose={handleCloseRejectModal}
      />
    </>
  );
};

export default ManagePoisPage;
