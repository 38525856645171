import { BaseButton, Block, Icon, XelaColor } from '@codepoint-pt/xela';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NOT_SENT, SENDING, SENT, StatusList } from '../../../models/Notification';
import { Notification } from '../../../models/Notification';
import { DateLabel, StatusLabel, StatusWrapper, Status } from './styles';
import dayjs from 'dayjs';

interface StatusProps {
  initialValues?: Notification;
  refreshStatus: () => void;
}

const StatusComponent: FC<StatusProps> = ({ initialValues, refreshStatus }) => {
  const { t } = useTranslation();
  
  return (
    <>
      {
        !!initialValues?._id &&
        <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
          <StatusWrapper>
            <StatusLabel>
              {t('STATUS')}:&nbsp;
              <Status color={StatusList.find(elem => elem._id === initialValues?.state.status)?.color}>{t(initialValues?.state.status || '')}</Status>
            </StatusLabel>
            {
              initialValues?.state.status === SENDING &&
              <BaseButton 
                compact
                action='secondary'
                variant='filled'
                leftIcon={<Icon icon='arrows_refresh' size={12} color='white' />}
                styles={() => ({
                  root: {
                    backgroundColor: XelaColor.Blue6
                  }
                })}
                onClick={refreshStatus}
              >
                {t('REFRESH_STATUS')}
              </BaseButton>
            }
          </StatusWrapper>
          {
            initialValues?.state.status !== NOT_SENT && initialValues?.state.start_date &&
            <DateLabel>
              {t('SEND_START_DATE')}: <span>{dayjs(initialValues?.state.start_date).format('DD/MM/YYYY, HH:mm')}h</span>
            </DateLabel>
          }
          {
            initialValues?.state.status === SENT && initialValues?.state.end_date &&
            <DateLabel>
              {t('SEND_END_DATE')}: <span>{dayjs(initialValues?.state.end_date).format('DD/MM/YYYY, HH:mm')}h</span>
            </DateLabel>
          }
        </Block>
      }
    </>
  );
};

export default StatusComponent;
