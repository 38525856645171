import { FieldRenderProps } from 'react-final-form';
import { AnyObject, GenericInput } from '../../models/Generic';
import { CreatePreviewFile, Icon, XelaColor, InputWrapper, showNotification, Dropzone, MIME_TYPES } from '@codepoint-pt/xela';
import React from 'react';
import styled from 'styled-components';

const Centered = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ImageSection = styled.div<{ ratio?: number }>`
  position: relative;
  width: 100%;
  padding-bottom: ${({ ratio }) => (ratio ? 100 * ratio : 100)}%;
`;

const HoverAction = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(248, 249, 250, 0.8);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  opacity: 0;
  border-radius: 16px;
`;

const HoverContent = styled.div<{ border: boolean; $error?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $error }) => ($error ? XelaColor.Red5 : XelaColor.Gray10)};
  ${({ border, $error }) =>
    border &&
    `border: 1px dashed ${$error ? XelaColor.Red5 : XelaColor.Gray10}`};
  border-radius: 16px;
`;

const BackgroundImage = styled.div<{ src: string; $color?: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ $color }) => $color || 'transparent'};
  border-radius: 16px;
  &:hover {
    ${HoverAction} {
      opacity: 1;
    }
  }
`;

export interface ImageInputProps
  extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: Date) => void;
  input: GenericInput<AnyObject>;
  label?: React.ReactNode;
  description?: React.ReactNode;
  ratio?: number;
  maxSize?: number;
  maxSizeText?: string;
  placeholder?: string;
  errorTitle?: string;
  errorMessage?: string;
}

export const ImageInput: React.FC<ImageInputProps> = ({
  input,
  meta,
  label,
  description,
  ratio,
  maxSize = 9000000,
  maxSizeText = '9MB',
  placeholder = 'Drag or drop image',
  errorTitle = 'Invalid image',
  errorMessage = 'The file you tried to upload is invalid or is bigger than #MAX_SIZE#',
}) => {
  const hasError = meta?.invalid && meta?.submitFailed;

  const handleAccept = (files: File[]) => {
    if (files[0]) {
      const img = CreatePreviewFile(files[0]);
      input.onChange(img);
    }
  };

  const handleReject = () => {
    showNotification({
      title: errorTitle,
      message: errorMessage.replace('#MAX_SIZE#', maxSizeText),
      color: 'red',
    });
  };

  const renderDropzone = (
    <ImageSection ratio={ratio}>
      <HoverContent border={!input.value} $error={hasError}>
        {input.value ? (
          <BackgroundImage
            $color={input?.value?.color}
            src={input.value.preview || input.value.url}
          >
            <HoverAction>
              <Icon icon="authoring_image" size={40} color="#a0a0a0" />
            </HoverAction>
          </BackgroundImage>
        ) : (
          <Centered dir="column">
            <Icon
              icon="authoring_image"
              size={40}
              color={hasError ? XelaColor.Red5 : XelaColor.Gray10}
            />
            <div>{placeholder}</div>
          </Centered>
        )}
      </HoverContent>
    </ImageSection>
  );

  return (
    <InputWrapper label={label} description={description}>
      <Dropzone
        onDrop={handleAccept}
        onReject={handleReject}
        maxSize={maxSize}
        accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
        multiple={false}
        useFsAccessApi={false}
        styles={{
          root: {
            border: 'none',
            padding: 0
          }
        }}
      >
        {renderDropzone}
      </Dropzone>
    </InputWrapper>
  );
};

export default ImageInput;
