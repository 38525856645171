/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormSpy, Field } from 'react-final-form';
import { usePrompt } from '../../hooks/use-prompt/use-prompt';

const FormPrompt: React.FC<any> = ({ when, message }) => {
  usePrompt(message, when);
  return (
    <FormSpy subscription={{ dirtyFields: true }}>
      {({ dirtyFields }) =>
        Object.keys(dirtyFields).map((name, idx) => (
          <Field key={idx} name={name} subscription={{}} render={() => null} />
        ))
      }
    </FormSpy>
  );
};

export default FormPrompt;
