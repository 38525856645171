import { Field, withTypes } from 'react-final-form';

import { BasicForm } from '../../../../styles/BasicStyles';
import { useValidationSchema } from '../../../../hooks/use-validation/use-validation-schema';
import {
  Modal,
  XelaColor,
  Icon,
  Grid,
  BaseButton,
  TextInput
} from '@codepoint-pt/xela';
import yup, { isUrl } from '@codepoint-pt/yup-validations';
import { useTranslation } from 'react-i18next';
import { ContactBlock } from '../../../../models/Itinerary';

interface Params {
  opened: boolean;
  onClose: (changed?: ContactBlock) => void;
  initialValues?: ContactBlock;
}

const { Form } = withTypes<ContactBlock>();

const BlockModal = ({ opened, onClose, initialValues }: Params) => {
  const { t } = useTranslation();
  const validatedSchema = useValidationSchema(
    yup.object({
      title: yup.string().required(),
      website: isUrl
    })
  );
  const onSubmit = async (values: ContactBlock) => {
    onClose(values);
  };

  return (
    <Modal
      overlayColor={XelaColor.Gray11}
      padding="xl"
      size="xl"
      centered
      opened={opened}
      onClose={() => onClose()}
      title={t('CONTACT')}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      {opened && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validatedSchema}
        >
          {({ handleSubmit }) => {
            return (
              <BasicForm onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Col span={12}>
                    <Field name="title">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('NAME')}
                          icon={<Icon icon="essentials_user" size={14} />}
                          type="text"
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="website">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('WEBSITE')}
                          icon={
                            <Icon icon="essentials_globeAfrica" size={14} />
                          }
                          type="text"
                          description={t('INPUT_WEBSITE_INSTRUCTIONS')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="email">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('EMAIL')}
                          icon={<Icon icon="essentials_envelope" size={14} />}
                          type="email"
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="phonenumber">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={t('CONTACT')}
                          icon={<Icon icon="essentials_phone" size={14} />}
                          type="text"
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12} style={{ textAlign: 'right' }}>
                    <BaseButton
                      type="submit"
                      leftIcon={<Icon color="white" icon="essentials_save" />}
                    >
                      {t('SAVE')}
                    </BaseButton>
                  </Grid.Col>
                </Grid>
              </BasicForm>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};

export default BlockModal;
