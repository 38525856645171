import { Block, Grid, TextInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import ParseField from '../../../components/field/ParseField';

const ContactsTab = () => {
  const { t } = useTranslation();
  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <ParseField name="address">
            {(props) => (
              <TextInput
                {...props}
                label={t('ADDRESS')}
                placeholder={t('INPUT_ADDRESS_PLACEHOLDER')}
              />
            )}
          </ParseField>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ParseField name="contact">
            {(props) => (
              <TextInput
                {...props}
                label={t('NAME')}
                placeholder={t('INPUT_NAME_PLACEHOLDER')}
              />
            )}
          </ParseField>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ParseField name="phone">
            {(props) => (
              <TextInput
                {...props}
                label={t('PHONE')}
                placeholder={t('INPUT_PHONE_PLACEHOLDER')}
              />
            )}
          </ParseField>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ParseField name="phone2">
            {(props) => (
              <TextInput
                {...props}
                label={t('SECONDARY_PHONE')}
                placeholder={t('INPUT_PHONE_PLACEHOLDER')}
              />
            )}
          </ParseField>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ParseField name="email">
            {(props) => (
              <TextInput
                {...props}
                label={t('EMAIL')}
                placeholder={t('INPUT_EMAIL_PLACEHOLDER')}
              />
            )}
          </ParseField>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ParseField name="website">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('WEBSITE')}`}
                placeholder={t('INPUT_WEBSITE_PLACEHOLDER')}
                description={t('INPUT_WEBSITE_INSTRUCTIONS')}
              />
            )}
          </ParseField>
        </Grid.Col>
        <Grid.Col xs={12}>
          <ParseField name="website_reservation">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('WEBSITE_RESERVATION')}`}
                placeholder={t('INPUT_WEBSITE_PLACEHOLDER')}
                description={t('INPUT_WEBSITE_INSTRUCTIONS')}
              />
            )}
          </ParseField>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default ContactsTab;
