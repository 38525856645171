import {
  Divider,
  HStack,
  Icon,
  ScrollArea,
  SearchInput,
  VStack,
  Block
} from '@codepoint-pt/xela';
import { MenuWrapper, MenuOptionsWrapper } from './MenuStyles';
import { User } from '../../models/User';
import { MenuOptionProps } from './MenuOptions';
import Item from './components/Item';
import Group from './components/Group';
import UserComponent from './components/User';
import {
  LogoWrapper,
  CollapseMenuWrapper,
  SearchWrapper,
  SearchIconWrapper
} from './MenuStyles';
import { useState } from 'react';
import LanguageSelector from '../languages/LanguageSelector';

let timeout: NodeJS.Timer;

interface MenuProps {
  isOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setHovered: (open: boolean) => void;
  menuOptions: MenuOptionProps[];
  user: User | undefined;
  handleSearch?: (search: string) => void;
  handleLogout?: React.MouseEventHandler<HTMLDivElement> | undefined;
  handleProfile?: React.MouseEventHandler<HTMLDivElement> | undefined;
  logo: {
    image: string;
    maxWidth?: string;
    maxHeight?: string;
  };
}

const Menu: React.FC<MenuProps> = ({
  isOpen,
  setMenuOpen,
  menuOptions,
  setHovered,
  user,
  handleSearch,
  handleLogout,
  handleProfile,
  logo
}) => {
  const [search, setSearch] = useState('');
  const renderMenuOptions = (option: MenuOptionProps) => {
    if(option.superuser && !user?.superuser) return null;

    if(user?.type && option.roles?.length && !option.roles.includes(user?.type)) return null;
    
    if (option.type === 'divider') {
      return <Divider key={option.name} variant="dotted" />;
    }

    if (option.type === 'link') {
      return <Item key={option.name} isOpen={isOpen} option={option} />;
    }

    if (option.type === 'dropdown') {
      return (
        <Group
          key={option.name}
          isOpen={isOpen}
          option={option}
          renderMenuOptions={renderMenuOptions}
        />
      );
    }

    return null;
  };

  const searchHandler = (value: string, time: number) => {
    setSearch(value);
    if (!!handleSearch) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleSearch(value);
        setSearch('');
      }, time);
    }
  };

  return (
    <MenuWrapper isOpen={isOpen}>
      <VStack>
        <HStack justifyContent="space-between" height="auto">
          <LogoWrapper
            isOpen={isOpen}
            maxWidth={logo.maxWidth}
            maxHeight={logo.maxHeight}
          >
            <img src={logo.image} alt="logo" />
          </LogoWrapper>
          <CollapseMenuWrapper
            onClick={() => setMenuOpen(!isOpen)}
            alignItems="center"
            justifyContent={isOpen ? 'flex-end' : 'center'}
            width="48px"
            height="48px"
          >
            <Icon icon={isOpen ? 'basics_left' : 'basics_right'} />
          </CollapseMenuWrapper>
        </HStack>
        {!!handleSearch &&
          (isOpen ? (
            <SearchWrapper>
              <SearchInput
                width="100%"
                input={{
                  value: search,
                  onChange: (v: string | null) => searchHandler(v || '', 1000)
                }}
                onSearch={(v: string | null) => searchHandler(v || '', 0)}
              />
            </SearchWrapper>
          ) : (
            <SearchIconWrapper>
              <Icon icon="basics_search" />
            </SearchIconWrapper>
          ))}

        {menuOptions?.length > 0 && (
          <MenuOptionsWrapper
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <ScrollArea
              type="hover"
              style={{
                width: '100%',
                height: 'calc(100vh - 310px)'
              }}
              scrollbarSize={5}
            >
              <VStack spacing="6px">
                {menuOptions.map((option) => renderMenuOptions(option))}
              </VStack>
            </ScrollArea>
          </MenuOptionsWrapper>
        )}
      </VStack>
      <Block>
        <LanguageSelector collapse={!isOpen} />
        <Divider variant="solid" style={{ margin: '10px 0' }} />
        <UserComponent
          isOpen={isOpen}
          user={user}
          handleLogout={handleLogout}
          handleProfile={handleProfile}
        />
      </Block>
    </MenuWrapper>
  );
};

export default Menu;
