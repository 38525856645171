import { useTranslation } from 'react-i18next';

const ActiveTranslation = ({ value }) => {
  const { t, i18n } = useTranslation();
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value;
  }
  if (!value || typeof value !== 'object') return t('TRANSLATION_NOT_FOUND');
  if (value[i18n.resolvedLanguage] && value[i18n.resolvedLanguage] !== '')
    return value[i18n.resolvedLanguage];
  return t('TRANSLATION_NOT_FOUND');
};

export const ActiveTranslationNoHook = ({ t, i18n, value }) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value;
  }
  if (!value || typeof value !== 'object') return t('TRANSLATION_NOT_FOUND');
  if (value[i18n.resolvedLanguage] && value[i18n.resolvedLanguage] !== '')
    return value[i18n.resolvedLanguage];
  return t('TRANSLATION_NOT_FOUND');
};

export default ActiveTranslation;
