import { Block, Grid, TextInput } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TranslatableFields from '../../../components/inputs/TranslatableFields';

interface InformationTabProps {
  subValidate: (fields: string[]) => boolean;
}

const InformationTab: FC<InformationTabProps> = ({ subValidate }) => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <Field name="name">
            {(props) => <TextInput {...props} label={t('NAME')} />}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'textarea'
              }
            ]}
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
