import { LoadingOverlay, PageHeader, toFormData } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { withTypes } from 'react-final-form';
import { User } from '../../models/User';
import { BasicForm } from '../../styles/BasicStyles';
import { useContext, useState, useEffect } from 'react';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { InfoContext } from '../../Context';
import { ValidationErrors } from 'final-form';
import { Poi } from '../../models/Poi';
import { createUTCDate } from '../../utils/dates';
import yup from '@codepoint-pt/yup-validations';
import useFetch from 'use-http';
import FormPrompt from '../../components/prompt/FormPrompt';
import headerActions from '../../components/header/HeaderActions';
import InformationTab from './tabs/InformationTab';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import PassportTab from './tabs/PassportTab';
import BaseTabs from '../../components/tabs/BaseTabs';

const { Form } = withTypes<User>();

const ManageUserPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<User>();
  const [pois, setPois] = useState<Poi[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { info } = useContext(InfoContext);
  const { get, put, del } = useFetch('/users');
  const { get: getPois } = useFetch('/pois');

  useEffect(() => {
    const init = async () => {
      if(id) {
        const { data, success } = await get(`/${id}`);
        if(success) setInitialValues({ ...data, country: data.country?._id, gender: data.gender?._id });

        const poisResult = await getPois();
        if(poisResult?.success) setPois(poisResult?.data || []);
      }

      setLoading(false);
    };
    init();
  }, [id]);

  

  const onSubmit = async (values: User) => {
    if(values.birth && typeof values.birth !== 'string') {
      const newBirthday = createUTCDate(values.birth.getFullYear(), values.birth.getMonth(), values.birth.getDate());
      values.birth = newBirthday;
    }

    const payload = toFormData(values);
    const { success } = await put(`/${id}`, payload);
    if (success) navigate('/users');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/users');
      else setLoading(false);
    }
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: (
          <InformationTab
            initialValues={initialValues}
            genders={info?.genders}
            countries={info?.countries}
            interests={info?.interests}
          />
        ),
        error: useSectionValidation(
          errors,
          ['name', 'email'],
          submitFailed
        )
      },
      {
        value: 'passport',
        label: t('DOURO_PASSPORT'),
        children: (
          <PassportTab
            initialValues={initialValues}
            pois={pois}
          />
        )
      },
    ];

    return tabs;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required(),
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_USER' : 'ADD_USER')}
            onBack={() => navigate('/users')}
            breadcrumbs={[
              { title: t('USERS'), href: '/users' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/users',
              onDelete: id ? onDelete : undefined,
              deleteTitle: 'DELETE_USER',
              deleteMessage: 'DELETE_USER_MESSAGE'
            })}
          />
          <BaseTabs
            baseTab="information"
            tabs={defineTabs(errors, submitFailed)}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageUserPage;
