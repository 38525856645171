import {
  AnyObject,
  DefaultListPage,
  Grid,
  SearchInput
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { DynamicPage } from '../../models/DynamicPage';
import ActiveTranslation from '../../components/languages/ActiveTranslation';

const DynamicPagesListPage = DefaultListPage<DynamicPage>();

const DynamicPagesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    },
    { title: t('Tag'), dataIndex: 'tag', sortable: true }
  ];

  const { get } = useFetch('/dynamic-pages');

  return (
    <DynamicPagesListPage
      translate={t}
      request={{
        get,
        searchFields: ['title|intl', 'tag']
      }}
      storage="DYNAMIC_PAGES_FILTERS"
      header={{
        navigate,
        title: t('DYNAMIC_PAGES'),
        actions: [],
        breadcrumbs: [{ title: t('DYNAMIC_PAGES'), href: '/dynamic-pages' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/dynamic-pages/${row._id}`)
      }}
    />
  );
};

export default DynamicPagesPage;
